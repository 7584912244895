import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

export const isNullOrUndefine = (value) =>
  value === null || value === undefined;

export const toCommas = (value) => {
  if (isNullOrUndefine(value)) return "";

  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "VND",
  }).format(value);
};

export const toThousands = (value) => {
  if (isNullOrUndefine(value)) return "";

  let number = parseFloat(value) / 1000;
  return new Intl.NumberFormat("de-DE").format(number);
};

export const flashTitle = (pageTitle, newTitle) => {
  if (document.title === pageTitle) {
    document.title = newTitle;
  } else {
    document.title = pageTitle;
  }
}

export const onGridReady = (params) => {
  // try {
  //   if (!params?.api) return;
  //   params?.api?.sizeColumnsToFit();
  // }
  // catch { }
};

export const dateFormatDMY = (val) => {
  if (isNullOrUndefine(val)) return "TBD";
  return val && moment(val).format("DD-MMM-YYYY");
};

export const dateFormatShortDMY = (val) => {
  if (isNullOrUndefine(val)) return "TBD";
  return val && moment(val).format("DD-MMM-YY");
};

export const dateRangeDMY = (fromDate, toDate) => {
  if (isNullOrUndefine(fromDate) && isNullOrUndefine(toDate)) return "";
  let strFrom = dateFormatDMY(fromDate) ?? "TBD";
  let strTo = dateFormatDMY(toDate) ?? "TBD";
  return `${strFrom} => ${strTo}`;
};

export const nextMonth = () => {
  let now = new Date();
  return now.setMonth(now.getMonth() + 1);
}

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const newGuid = () => {
  return uuidv4();
}

export const getBackgroundColorByStatus = (statusName) => {
  if (statusName === "To do") return "#EEE8AA";
  if (statusName === "Working") return "#4169E1";
  if (statusName === "Waiting approval") return "#ADD8E6";
  if (statusName === "Done") return "#8FBC8B";
  if (statusName === "Pending") return "#BA55D3";

  return "gray";
}

export const statusOfTasks = [
  { id: 1, name: "To do", statusName: "To do" },
  { id: 2, name: "Working", statusName: "Working" },
  { id: 3, name: "Waiting approval", statusName: "Waiting approval" },
  { id: 4, name: "Done", statusName: "Done" },
  { id: 5, name: "Pending", statusName: "Pending" },
];

export const defaultBoxWidth = 480;
export const defaultBoxMinWidth = 280;
export const minSizeWindowPC = 768;

export const userRoles = {
  Admin: "Admin",
  Teamleader: "Teamleader",
  Accountant: "Accountant"
}

export const taskPermission = (userRole) => {
  return userRole === "Admin" || userRole === "Manager";
};

export const getStatusIcon = (statusName) => {
  return statusName === "To do" ? "business-time" : statusName === "Done" ? "check-circle" : "tasks";;
};

export const cellStyleDone = { textAlign: "left", color: 'green', fontWeight: "bold", textDecorationLine: "line-through" };
export const cellStyleOverdue = { textAlign: "left", color: 'orange', fontWeight: "bold" };