import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { toNumber } from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBAccordion, MDBAccordionItem, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import { userRoles } from "../../utils/utilities";
import MyAccountantTasks from "./MyAccountantTasks";
import MyTabContent from "./MyTabContent";

function MyTasks() {
  const navigate = useNavigate();
  const [todayTasks, setTodayTasks] = useState([]);
  const [myTasks, setMyTasks] = useState([]);
  const [monthTasks, setMonthTasks] = useState([]);
  const [userRole, setUserRole] = useState();
  const [userId, setUserId] = useState();
  const [basicActive, setBasicActive] = useState('tab1');
  
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "My tasks";
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserId(toNumber(infoObj.id ?? 0));
    setUserRole(infoObj?.role ?? "")
  }, []);

  useEffect(() => {
    loadDataThisWeek();
    loadDataThisMonth();
    loadDataToday();
  }, []);

  const loadDataToday = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/Today`,
        configHeader()
      )
      .then((result) => {
        let data = result?.data ?? []
        setTodayTasks(data);
        // let items = data.filter((it) => it.staffId === userId) ?? [];
        // setTimesheets(items)
      });
  };

  const loadDataThisWeek = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/This-Week`,
        configHeader()
      )
      .then((result) => {
        setMyTasks(result?.data ?? []);
      });
  };

  const loadDataThisMonth = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/This-Month`,
        configHeader()
      )
      .then((result) => {
        setMonthTasks(result?.data ?? []);
      });
  };

  const renderAdminTab = () => {
    return <MDBAccordion alwaysOpen initialActive={1}>
      <MDBAccordionItem collapseId={1} headerTitle="Project Tasks">
        {renderProjectTasks()}
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={2} headerTitle="Project Billings">
        <MyAccountantTasks />
      </MDBAccordionItem>
    </MDBAccordion>
  }

  const renderProjectTasks = () => {
    return <>
      <MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
            Today
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
            This Week
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
            This Month
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={basicActive === 'tab1'}>
          <MyTabContent myTasks={todayTasks ?? []} userId={userId} />
        </MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab2'}>
          <MyTabContent myTasks={myTasks ?? []} userId={userId} />
        </MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab3'}>
          <MyTabContent myTasks={monthTasks ?? []} userId={userId} />
        </MDBTabsPane>
      </MDBTabsContent>
    </>
  }
  const renderTotalProject = () => {
    if (userRole === userRoles.Admin) {
      return renderAdminTab();
    }

    if (userRole === userRoles.Accountant) return <MyAccountantTasks />
    
    return renderProjectTasks();
  }

  return (
    <>
      {renderTotalProject()}
      <ToastContainer />
    </>
  );
}

export default MyTasks;
