import { useEffect, useState } from "react";
import { MDBCheckbox, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon } from "mdb-react-ui-kit";
import CreatePhase from "../../modals/CreatePhase";
import CreateNewTask from "../../modals/CreateNewTask";
import CreateNewSubTask from "../../modals/CreateNewSubTask";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { configHeader } from "../../utils/tokenHelper";
import Multiselect from "multiselect-react-dropdown";

function ProjectHeader({
  project,
  phaseName,
  phaseId,
  phase,
  statuses,
  statusOfPhase,
  teams,
  taskInfo,
  staffsOfPhase,
  userRole,
  onSavePhase,
  onSaveTask,
  handleAddSubtask,
  reloadTaskInfo }) {
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [openPhaseModal, setOpenPhaseModal] = useState(false);
  const [openSubTaskModal, setOpenSubTaskModal] = useState(false);
  const [titleBoard, setTitleBoard] = useState([]);
  const [teamId, setTeamId] = useState();
  const [curTeams, setCurTeams] = useState(teams ?? []);
  const [pm, setPM] = useState();

  useEffect(() => {
    let items = [];

    project?.name && items.push(project?.name);
    phaseName?.length > 0 && items.push(phaseName);
    taskInfo?.taskName && items.push(taskInfo?.taskName);

    setPM(project?.pm ?? "");
    setTitleBoard(items ?? []);
  }, [taskInfo, project, phaseName]);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setTeamId(infoObj.teamId);
    reloadAllTeams();
  }, []);

  const onSaveSubTask = (data) => {
    handleAddSubtask(taskInfo?.taskId, data);
    setOpenSubTaskModal(false);
    reloadTaskInfo(taskInfo?.taskId);
  };

  const reloadAllTeams = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/Teams`,
        configHeader()
      )
      .then((result) => {
        let dataResults = result?.data?.dataResults ?? []
        setCurTeams(dataResults);
      });
  }

  const renderProjectManager = () => {
    if (pm?.length > 0) {
      return <div title="Project manager" style={{ marginLeft: 20 }}>
        <div className=" truncate max-w-[250px] ">
          <MDBIcon fas icon={"user-alt"} style={{ marginRight: 15 }} />{pm}
        </div>
      </div>
    }

    return <></>
  }

  const renderColumnSelections = () => {
    return <MDBDropdown style={{ height: 26}}>
      <MDBDropdownToggle color='success'>
        <MDBIcon fas icon="columns" />
      </MDBDropdownToggle>
      <MDBDropdownMenu>
        {(statuses ?? [])
          .filter((p) => (p?.statusName ?? "").length > 0)
          .map((item) => {
            return <MDBCheckbox name='inlineCheck'
              id={`chb${item.id}`}
              value={item?.statusName}
              label={item?.statusName} />
          })}
      </MDBDropdownMenu>
    </MDBDropdown>
  }

  const renderProjectSideBar = () => {
    return <>
      <div
        className=" flex items-center space-x-2  md:space-x-4"
        style={{ marginLeft: "6vw", maxWidth: 456 }}
      >
        <div className=" flex items-center ">
          <>
            <h3 className=" truncate max-w-[500px] md:text-2xl text-xl font-bold md:ml-20 font-sans  ">
              {titleBoard?.[0] ?? ""}
            </h3>
            <h5 className=" truncate max-w-[400px] md:text-2xl text-xl  md:ml-10 font-sans  ">
              {titleBoard?.[1] ?? ""}
            </h5>
            <h6 className=" truncate max-w-[300px] md:text-2xl text-xl  md:ml-10 font-sans  ">
              {titleBoard?.[2] ?? ""}
            </h6>
            {renderProjectManager()}
          </>
        </div>
      </div>
      {/* <div
        className=" flex items-end space-x-2  md:space-x-4"
        style={{ marginRight: 10 }}
      >
        {renderColumnSelections()}
      </div> */}
    </>
  }

  return (
    <div className=" p-1 fixed left-0 bg-white dark:bg-[#2b2c37] right-0 " style={{ zIndex: 100 }}>
      <header
        className=" flex justify-between dark:text-white items-center  "
        style={{
          color: "white",
          backgroundImage: "conic-gradient(yellow, #9e9ed1, black)",
          fontSize: 16,
          height: 26,
          marginTop: -16
        }}
      >
        {renderProjectSideBar()}
      </header>

      {openTaskModal && (phaseId ?? 0) > 0 && (
        <CreateNewTask
          phaseId={phaseId}
          phaseName={phaseName}
          statuses={statusOfPhase ?? []}
          teams={(curTeams ?? []).filter((item) => item.isActive || +item.status === 1)}
          userRole={userRole}
          isPM={phase?.isPM}
          onCloseModal={() => setOpenTaskModal(false)}
          onSave={(data) => onSaveTask(data)}
        />
      )}
      {openPhaseModal && (project?.id ?? 0) > 0 && (
        <CreatePhase
          projectId={project?.id}
          projectName={project?.name}
          statuses={statuses ?? []}
          onCloseModal={() => setOpenPhaseModal(false)}
          onSave={(data) => onSavePhase(data)}
        />
      )}
      {openSubTaskModal && (
        <CreateNewSubTask
          taskId={taskInfo?.taskId}
          teamId={teamId}
          taskName={taskInfo?.taskName}
          staffs={staffsOfPhase ?? []}
          isPM={taskInfo?.isPM}
          onCloseModal={() => setOpenSubTaskModal(false)}
          onSave={(data) => onSaveSubTask(data)}
        />
      )}
    </div>
  );
}

export default ProjectHeader;
