import { useEffect, useState } from "react";
import axios from "axios";
import { configHeader } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { useNavigate, useParams } from "react-router-dom";
import { MDBAccordion, MDBAccordionItem, MDBBadge, MDBBtn, MDBCheckbox, MDBCol, MDBIcon, MDBInput, MDBListGroup, MDBListGroupItem, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane, MDBTextArea } from "mdb-react-ui-kit";
import DisplayTaskProcessing from "../Planning/DisplayTaskProcessing";
import CtrPlanCompleted from "../Projects/CtrPlanCompleted";
import { first, orderBy } from "lodash";
import AssignTaskToTeam from "../../modals/AssignTaskToTeam";
import CurrentLogTimeStatus from "../Projects/CurrentLogTimeStatus";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AgGridReact } from "ag-grid-react";
import { cellStyleDone, cellStyleOverdue, dateFormatDMY, newGuid, nextMonth, statusOfTasks, userRoles } from "../../utils/utilities";
import DisplayOverdue from "../../components/DisplayOverdue";
import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import LogWork from "../Projects/LogWork";
import CreateNewSubTask from "../../modals/CreateNewSubTask";
import RevisionTaskInfo from "../../modals/RevisionTaskInfo";
import ApexPie from "../Dashboard/ApexPie";
import ColumnBarEstimate from "../Dashboard/ColumnBarEstimate";
import DisplayUserAndName from "../../components/DisplayUserAndName";

function PageViewTask() {
  let { id } = useParams();
  const [canModify, setCanModify] = useState(false);
  const [taskDetailInfo, setTaskDetailInfo] = useState();
  const [curTeams, setCurTeams] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [revisionTasks, setRevisionTasks] = useState([]);
  const [userTeamId, setUserTeamId] = useState();
  const [userRole, setUserRole] = useState();
  const [taskName, setTaskName] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [priorityNumber, setPriorityNumber] = useState();
  const [description, setDescription] = useState("");
  const [estimateHours, setEstimateHours] = useState();
  const [currentTeams, setCurrentTeams] = useState([]);
  const [showInMenu, setShowInMenu] = useState();
  const [checked, setChecked] = useState(false);
  const [statusName, setStatusName] = useState("To do");
  const [isLogTimeOpen, setIsLogTimeOpen] = useState(false);
  const [isRevision, setIsRevision] = useState(false);
  const [teamId, setTeamId] = useState();
  const [isOpenSubTaskModal, setIsOpenSubTaskModal] = useState(false);
  const [projectResources, setProjectResources] = useState({});
  const [minRow, setMinRow] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserTeamId(infoObj.teamId);
    let uRole = infoObj.role ?? "User"
    if (uRole === userRoles.Admin) {
      setCanModify(true);
    }
    setUserRole(uRole);
    reloadAllTeams();
  }, []);

  useEffect(() => {
    reloadTaskDetailInfo(id);
    reloadProjectResources(id);
    reloadAllTeams();
  }, [id]);

  const reloadProjectResources = (tId) => {
    tId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${tId}/Task-Resources`,
          configHeader()
        )
        .then((result) => {
          setProjectResources(result?.data);
        });
  };

  const reloadAllTeams = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/Teams`,
        configHeader()
      )
      .then((result) => {
        let dataResults = (result?.data?.dataResults ?? []).filter((item) => +item.status === 1 || item.isActive)
        setCurTeams(dataResults ?? []);
      });
  }

  const reloadTaskDetailInfo = (taskInfoId) => {
    taskInfoId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${taskInfoId}`,
          configHeader()
        )
        .then((result) => {
          let taskInfo = result?.data

          let data = (taskInfo?.subTasks ?? [])
            .map((item) => {
              let st = item?.statusName !== "Done";
              var time1 = moment(item?.endDate ?? nextMonth()).format('YYYY-MM-DD');
              var time2 = moment(new Date()).format('YYYY-MM-DD');
              let overdue = time1 < time2;
              item.overdue = st && overdue;
              return item;
            });

          setTaskDetailInfo(taskInfo);
          setRevisionTasks(taskInfo?.revisionTasks ?? [])
          setSubTasks(data ?? []);
          if (taskInfo?.isPM) setCanModify(true);
          setTaskName(taskInfo?.name);
          setStartDate(taskInfo?.startDate);
          setEndDate(taskInfo?.endDate);
          setPriorityNumber(taskInfo?.priorityNumber);
          setDescription(taskInfo?.description ?? "");
          setEstimateHours(taskInfo?.estimateHours);
          setCurrentTeams(taskInfo?.teamIds ?? []);
          setShowInMenu(taskInfo?.showInMenu);
          setStatusName(taskInfo?.statusName ?? "To do");
        });
  };

  const saveLogTime = (data) => {
    id &&
      axios
        .post(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${id}/LogWork`,
          data,
          configHeader()
        )
        .then(() => {
          reloadTaskDetailInfo();
          setIsLogTimeOpen(false);
        });
  }

  const renderTitleTask = () => {
    let titleTask = (subTasks ?? []).length < 1 ? "" : ` (${(subTasks ?? []).length} SubTasks)`;
    return <>
      <div style={{ marginRight: 125 }}>
        {taskDetailInfo?.name}
      </div>
      <CurrentLogTimeStatus
        statusName={taskDetailInfo?.statusName}
        estimateHours={taskDetailInfo?.estimateHours}
        normalHours={(taskDetailInfo?.normalHours ?? 0) + (taskDetailInfo?.curNormalHours ?? 0)}
        overTime={(taskDetailInfo?.overTimeHours ?? 0) + (taskDetailInfo?.curOverTimeHours ?? 0)}
        startDate={taskDetailInfo?.startDate}
        endDate={taskDetailInfo?.endDate}
        hideStatus
        hideDate
      />
      <div style={{ marginLeft: 45, marginRight: 45 }}>{taskDetailInfo?.statusName}</div>
      <div style={{ marginRight: 125 }}>{titleTask}</div>
    </>
  }

  const divCtrls = () => {
    let ctrlSave = (taskDetailInfo?.statusName === "Done" || !canModify) ? "" : <MDBBtn onClick={() => saveData()}>Save</MDBBtn>

    return <MDBRow style={{ marginTop: 25 }}>
      <MDBCol size="3" sm="3">
        {renderLogWork()}
      </MDBCol>
      <MDBCol size="3" sm="6"></MDBCol>
      <MDBCol size="3" sm="3">
        {ctrlSave}
        <MDBBtn color="secondary" style={{ marginLeft: 25 }} onClick={() => navigate(-1)}>
          Back
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  }

  const saveData = () => {
    if ((taskName ?? "").length < 1) {
      toast.error("Please select task again.");
    } else {
      let teamIds = currentTeams;

      if (userRole !== userRoles.Admin && !taskDetailInfo?.isPM) {
        teamIds = (curTeams ?? [])
          .filter((item) => +userTeamId === +item.id)
          .map((x) => x.id);
      }

      let data = checked ? {
        id: taskDetailInfo?.id,
        name: taskName,
        estimateHours: estimateHours,
        startDate: startDate,
        endDate: endDate,
        priorityNumber: priorityNumber,
        description: description,
        showInMenu: showInMenu ?? false,
        teamIds: teamIds,
        statusName: statusName,
      } : {
        id: taskDetailInfo?.id,
        name: taskName,
        estimateHours: estimateHours,
        startDate: startDate,
        priorityNumber: priorityNumber,
        description: description,
        showInMenu: showInMenu ?? false,
        teamIds: teamIds,
        statusName: statusName,
      };

      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}`,
          data,
          configHeader()
        )
        .then(() => {
          toast.success("Your task has been updated.");
        });
    }
  }

  const handleOpenSubTask = (tId) => {
    setIsOpenSubTaskModal(true);
    setTeamId(tId);
  };

  const renderCtrlAddSubTaskOfTeam = (statusDone, tId) => {
    if (statusDone) return <></>

    return <div
      style={{ cursor: "pointer", alignItems: "flex-end" }}
      onClick={() => handleOpenSubTask(tId)}
    >
      <MDBIcon fas icon="plus" />
    </div>
  }

  const renderTeamRevier = (info) => {
    if ((info?.reviewerId ?? 0) < 1) return <></>
    let myTeams = (curTeams ?? []).filter((item) => info?.teamId === +item?.id);
    let myTeam = first(myTeams);

    return <MDBRow>
      <MDBCol size="3" sm="4" title={myTeam?.name}>
        {myTeam?.name}
      </MDBCol>
      <MDBCol size="3" sm="4" title={`${info?.userReviewer} (${info?.reviewer})`}>
        <MDBBtn color="secondary">{`${info?.userReviewer} (${info?.reviewer})`} </MDBBtn>
      </MDBCol>
      <MDBCol size="3" sm="4" title={dateFormatDMY(info?.reviewDate)}>
        {dateFormatDMY(info?.reviewDate)}
      </MDBCol>
    </MDBRow>
  }

  const renderTeamsTask = (statusDone, curTeams) => {
    return (curTeams ?? []).map((item) => {
      let isChecked =
        (taskDetailInfo?.teamIds ?? []).filter((it) => it === +item?.id)
          ?.length > 0;

      return (
        <MDBRow title={`Team ${item?.name}`}>
          <MDBCol size="3" sm="9">
            <AssignTaskToTeam
              info={item}
              canModify={true}
              isChecked={isChecked}
              handleUseTeam={(teamId, checked) =>
                handleUseTeam(teamId, checked)
              }
            />
          </MDBCol>
          <MDBCol size="3" sm="3">
            {isChecked && renderCtrlAddSubTaskOfTeam(statusDone, item?.id)}
          </MDBCol>
        </MDBRow>
      );
    });
  }

  const [basicActive, setBasicActive] = useState('tabSummary');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  const renderReviewTeams = () => {
    let items = (taskDetailInfo?.teamReviews ?? []).filter((info) => (info?.reviewerId ?? 0) > 0);
    if ((items ?? []).length < 1) return <h5>Not yet any team review or approval.</h5>

    return (items ?? []).map((teamReview) => {
      return renderTeamRevier(teamReview);
    })
  }

  const renderTeamByUserRole = () => {
    let statusDone = taskDetailInfo?.statusName === "Done";

    if (userRole !== userRoles.Admin && !taskDetailInfo?.isPM) {
      let current = (curTeams ?? []).filter((item) => +userTeamId === +item.id);
      let info = first(current);
      let teamReviews = (taskDetailInfo?.teamReviews ?? []).filter((item) => +userTeamId === item.teamId);
      let teamReview = first(teamReviews) ?? {};

      return (
        <>
          <MDBRow title={`My team ${info?.name}`}>
            <MDBCol size="3" sm="9" color="secondary">
              <MDBBtn color="secondary">My team {info?.name}</MDBBtn>
            </MDBCol>
            <MDBCol size="3" sm="3">
              {renderCtrlAddSubTaskOfTeam(statusDone, info?.id)}
            </MDBCol>
          </MDBRow>
          {renderTeamRevier(teamReview)}
        </>
      );
    }

    return (
      <>
        <h5>Teams</h5>
        <MDBTabs className='mb-3'>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick(`tabSummary`)} active={basicActive === `tabSummary`}>
              Team Info
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick(`tabReview`)} active={basicActive === `tabReview`}>
              Review Info
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>

        <MDBTabsContent>
          <MDBTabsPane open={basicActive === `tabSummary`}>
            {renderTeamsTask(statusDone, curTeams)}
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === `tabReview`}>
            {renderReviewTeams()}
          </MDBTabsPane>
        </MDBTabsContent>

      </>
    );
  };

  const handleUseTeam = (teamId, checked) => {
    let current = currentTeams ?? [];
    if (checked) {
      let hasVal = (currentTeams ?? []).filter((it) => it === teamId);
      if ((hasVal ?? []).length < 1) {
        current.push(teamId);
      }
    } else {
      current = (currentTeams ?? []).filter((it) => it !== teamId);
    }
    setCurrentTeams(current ?? []);
  };

  const optStatus = () => {
    return (statusOfTasks ?? []).map((p) => {
      return { name: p.name, id: p.id };
    });
  };

  const onSelectedStatusValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (statusOfTasks ?? []).filter((p) => ids.some((id) => id === p.id));
    let pro = first(rs) ?? {};
    setStatusName(pro?.name);
  };

  const renderLogWork = () => {
    let hasTeam = (taskDetailInfo?.teamIds ?? [])
      .filter((item) => +userTeamId === +item)
      ?.length > 0;

    if (!hasTeam && userRole !== userRoles.Admin && !taskDetailInfo?.isPM) return <></>;

    let statusDone = taskDetailInfo?.statusName === "Done";
    if (!taskDetailInfo?.hasRevision && statusDone) return <>
      <MDBBtn color="secondary" onClick={() => setIsRevision(true)} style={{ cursor: "pointer" }}>
        <MDBIcon fas icon="dna" /> Revision
      </MDBBtn>
    </>;

    let noSubTask = subTasks?.length < 1;
    if (taskDetailInfo?.hasRevision || noSubTask) return <></>;

    return <MDBBtn color="info" style={{ cursor: "pointer" }}
      onClick={() => setIsLogTimeOpen(true)}>Log Working
    </MDBBtn>
  };

  const renderBasicInfo = () => {
    return <>
      <MDBRow style={{ textAlign: "left" }}>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            type="number"
            label="Estimate"
            title="Estimate Hours"
            value={estimateHours}
            style={{ padding: 5 }}
            onChange={(evt) => setEstimateHours(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            type="number"
            label="Priority"
            value={priorityNumber}
            style={{ padding: 5 }}
            onChange={(evt) => setPriorityNumber(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="6">
          <MDBCheckbox
            id={`chb${newGuid()}`}
            label='Show In Menu'
            checked={showInMenu}
            onChange={(e) => setShowInMenu(e.target.checked)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ textAlign: "left", marginBottom: 10 }}>
        <MDBCol size="3" sm="3">
          Status Name
        </MDBCol>
        <MDBCol size="3" sm="6">
          <Multiselect
            options={optStatus()}
            singleSelect
            placeholder="Select a Status"
            label="Status Name"
            style={{ padding: 1 }}
            onSelect={(items) => onSelectedStatusValues(items)}
            onRemove={(items) => onSelectedStatusValues(items)}
            displayValue="name"
          />
        </MDBCol>
      </MDBRow>

      <CtrPlanCompleted
        startDate={startDate}
        endDate={endDate}
        onSaveStartDate={(date) => setStartDate(date)}
        onSaveEndDate={(date) => setEndDate(date)}
        onSaveChecked={(isSelected) => setChecked(isSelected)}
      />
    </>
  }

  const colDefs = [
    {
      field: "assigned",
      width: 215,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      filter: true,
      pinned: 'left',
      lockPinned: true,
      headerName: "Staff",
      cellRenderer: (row) => {
        return <DisplayUserAndName userName={row?.data?.userName} staffName={row?.data?.assigned} />
      },
    },
    {
      field: "name",
      pinned: 'left',
      lockPinned: true,
      width: 175,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      filter: true,
    },
    {
      field: "taskId",
      headerName: "Processing",
      width: 195,
      cellRenderer: (row) => {
        return <DisplayTaskProcessing
          statusName={row?.data?.statusName}
          planHours={row?.data?.estimateHours}
          workingHours={row?.data?.normalHours}
          otHours={row?.data?.overTimeHours}
        />;
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "team",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      filter: true,
      headerName: "Team"
    },
    {
      field: "estimateHours",
      width: 95,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      headerName: "Estimate"
    },
    {
      field: "overdue",
      headerName: "Overdue",
      width: 95,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        return <DisplayOverdue
          statusName={row?.data?.statusName ?? ""}
          endDate={row?.data?.endDate}
        />
      }
    },
    {
      field: "statusName",
      cellStyle: {
        textAlign: "left",
      },
      width: 135,
      headerName: "Status"
    },
    {
      field: "startDate",
      width: 175,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "endDate",
      width: 175,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "description",
      cellStyle: {
        'white-space': minRow ? "" : 'pre',
        textAlign: "left",
      },
      filter: true,
      autoHeight: true,
      headerName: "Description"
    }
  ];

  const createSiteBar = () => {
    let ctrMin = minRow ? "" : <MDBIcon fas icon="minus-square"
      onClick={() => setMinRow(true)}
      title="Minimize view description"
      style={{ cursor: "pointer", marginRight: 25 }} />;

    let ctrExternal = !minRow ? "" : <MDBIcon
      fas
      icon="eye"
      title="External view description"
      onClick={() => setMinRow(false)}
      style={{ cursor: "pointer", marginRight: 25 }}
    />

    return <MDBRow>
      <MDBCol sm="9" />
      <MDBCol sm="3" className="displayEnd">
        {ctrMin} {ctrExternal}
      </MDBCol>
    </MDBRow>
  }

  const [subActive, setSubActive] = useState('tabSubSummary');
  const handleSubClick = (value) => {
    if (value === subActive) {
      return;
    }

    setSubActive(value);
  };

  const renderAccordionSubTasks = () => {
    if (subTasks?.length < 1) return <></>
    let names = (subTasks ?? []).filter((it) => (it.estimateHours ?? 0) > 0).map((it) => `[${it.id}] ${it.name}`);
    let estimateHours = (subTasks ?? []).filter((it) => (it.estimateHours ?? 0) > 0).map((it) => it.estimateHours ?? 0);

    return <MDBAccordionItem collapseId={3} headerTitle="SubTasks information" title={taskDetailInfo?.description}>
      <MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleSubClick(`tabSubSummary`)} active={subActive === `tabSubSummary`}>
            Overview
          </MDBTabsLink>
        </MDBTabsItem>

        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleSubClick(`tabSubReview`)} active={subActive === `tabSubReview`}>
            Status
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={subActive === `tabSubSummary`}>
          <MDBRow style={{ textAlign: "left" }}>
            <MDBCol size="3" sm="8">
              {createSiteBar()}
              <div className="ag-theme-quartz" style={{ height: "45vh" }}>
                <AgGridReact
                  rowData={orderBy(subTasks ?? [], "priorityNumber")}
                  columnDefs={colDefs}
                  pagination
                  paginationPageSize={10}
                />
              </div>
            </MDBCol>
            <MDBCol size="3" sm="4">
              <ApexPie labels={names ?? []} series={estimateHours ?? []} />
            </MDBCol>
          </MDBRow>
        </MDBTabsPane>
        <MDBTabsPane open={subActive === `tabSubReview`}>
          {renderSubReview(orderBy(subTasks ?? [], "priorityNumber"))}
        </MDBTabsPane>
      </MDBTabsContent>

    </MDBAccordionItem>
  }

  const handleChange2SubStatus = (isChecked, subId, subStatus) => {
    if (subId && isChecked) {
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/Subtasks/${subId}/Status`,
          { status: subStatus ?? "Working" },
          configHeader()
        )
        .then(() => {
          reloadTaskDetailInfo(id);
          toast.success(`Status was changed to ${subStatus} successful.`);
        });
    }
  }

  const renderSubReview = (items) => {
    if ((items ?? [])?.length < 1) return <></>

    return <>
      <MDBRow style={{ textAlign: "left" }}>
        <MDBCol size="3" sm="6"></MDBCol>
        <MDBCol size="3" sm="3">
          <MDBRow>
            <MDBCol sm="4">To do</MDBCol>
            <MDBCol sm="4">Working</MDBCol>
            <MDBCol sm="4">Done</MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      {(items ?? []).map((data) => {
        let isDone = (data?.statusName ?? "") === "Done";
        return <MDBRow style={{ textAlign: "left" }} title={`Name : ${data?.name ?? ""}, Description : ${data?.description ?? ""}`}>
          <MDBCol size="3" sm="3" style={{ color: isDone ? "green" : "", fontWeight: isDone ? "bold" : "" }}>
            <DisplayUserAndName userName={data?.userName} staffName={data?.assigned} />
          </MDBCol>
          <MDBCol size="3" sm="3" style={{ color: isDone ? "green" : "", fontWeight: isDone ? "bold" : "" }}>
            {data?.name}
          </MDBCol>
          <MDBCol size="3" sm="3">
            <MDBRow>
              <MDBCol sm="4">
                <MDBCheckbox
                  id={`chb${newGuid()}`}
                  checked={(data?.statusName ?? "") === "To do"}
                  title={`Change ${data?.name} to status "To do"`}
                  onChange={(e) => handleChange2SubStatus(e.target.checked, data?.id, "To do")}
                />
              </MDBCol>
              <MDBCol sm="4">
                <MDBCheckbox
                  id={`chb${newGuid()}`}
                  checked={(data?.statusName ?? "") === "Working"}
                  title={`Change ${data?.name} to status "Working"`}
                  onChange={(e) => handleChange2SubStatus(e.target.checked, data?.id, "Working")}
                />
              </MDBCol>
              <MDBCol sm="4">
                <MDBCheckbox
                  id={`chb${newGuid()}`}
                  checked={(data?.statusName ?? "") === "Done"}
                  title={`Change ${data?.name} to status "Done"`}
                  onChange={(e) => handleChange2SubStatus(e.target.checked, data?.id, "Done")}
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol size="3" sm="3">
            <DisplayTaskProcessing
              statusName={data?.statusName}
              planHours={data?.estimateHours}
              workingHours={data?.normalHours}
              otHours={data?.overTimeHours}
            />
          </MDBCol>
        </MDBRow>
      })}
    </>
  }

  const renderRevisionTasks = () => {
    if (revisionTasks?.length > 0) {
      return <MDBAccordionItem collapseId={5} headerTitle={<>
        <div style={{ marginRight: 125 }}>Revision information</div>
        <CurrentLogTimeStatus
          estimateHours={taskDetailInfo?.estimateHours}
          normalHours={taskDetailInfo?.curNormalHours ?? 0}
          overTime={taskDetailInfo?.curOverTimeHours ?? 0}
          hideStatus
          hideDate
        />
      </>} title={taskDetailInfo?.description}>
        <MDBListGroup style={{ textAlign: "left", minWidthL: '22rem' }} light>
          {(revisionTasks ?? []).map((item) => {
            let iconView = +id === +item?.taskId ? "" : <MDBIcon fas icon="share-square"
              style={{ cursor: "pointer", marginRight: 15 }}
              title={`View task ${item?.name}`}
              onClick={() => navigate(`/tasks/${item?.taskId ?? 0}`)}
            />
            return <MDBListGroupItem style={{ textAlign: "left" }} className='px-3'>
              <MDBRow style={{ textAlign: "left", margin: 10 }}>
                <MDBCol size="3" sm="8">
                  {iconView} {item?.name}
                  <MDBTextArea
                    className="mb-3"
                    id="textAreaDescription"
                    rows="{3}"
                    value={item?.description ?? ""}
                    readOnly
                    disabled
                  />
                </MDBCol>
                <MDBCol size="3" sm="4">
                  <CurrentLogTimeStatus
                    statusName={item?.statusName}
                    estimateHours={item?.estimateHours}
                    startDate={item?.startDate}
                    endDate={item?.endDate}
                    hideStatus
                  />
                </MDBCol>
              </MDBRow>
            </MDBListGroupItem>
          })}
        </MDBListGroup>
      </MDBAccordionItem>
    }
    return <></>
  }

  const onSaveSubTask = (data) => {
    id &&
      axios
        .post(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${id}/Subtask`,
          data,
          configHeader()
        )
        .then(() => {
          reloadTaskDetailInfo(id);
          setIsOpenSubTaskModal(false);
          toast.success("Your subtask has been created.");
        });
  };

  const onAddRevisionTask = (data) => {
    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${id}/Revision`,
        data,
        configHeader()
      )
      .then(() => {
        reloadTaskDetailInfo(id);
        setIsRevision(false);
        toast.success("Your task has been added new revision.");
      });
  };

  const renderTaskName = (totalSub, totalDone, taskName) => {

    if (totalSub < 1) return <MDBCol size="3" sm="9">
      {taskName ?? ""}
    </MDBCol>

    return <>
      <MDBCol size="3" sm="2">
        <MDBBadge pill light title={"Subtasks has been completed"}>
          {`${totalDone} / ${totalSub}`}
        </MDBBadge>
      </MDBCol>
      <MDBCol size="3" sm="7">
        {taskName ?? ""}
      </MDBCol>
    </>
  }

  const renderBarEstimate = () => {
    let categories = [];
    let dataEstimates = [];
    let dataNormals = [];
    let dataOTs = [];

    (subTasks ?? []).map((item) => {
      categories.push(item?.name);
      dataEstimates.push(item?.estimateHours ?? 0);
      dataNormals.push(item?.normalHours ?? 0);
      dataOTs.push(item?.overTimeHours ?? 0);
    })

    if (categories.length < 1) return <></>

    return <ColumnBarEstimate categories={categories}
      dataEstimates={dataEstimates}
      dataNormals={dataNormals}
      dataOTs={dataOTs}
    />
  }

  const renderMainTask = () => {
    var totalSub = (subTasks ?? []).length;
    var totalDone = (subTasks ?? []).filter((it) => it.statusName === "Done")?.length;

    return <>
      <MDBRow style={{ textAlign: "left", margin: 10 }}>
        <MDBCol size="3" sm="3">
          <MDBIcon fab icon="product-hunt" style={{ marginRight: 15 }} /> Project
        </MDBCol>
        <MDBCol size="3" sm="9">
          {taskDetailInfo?.projectName}
        </MDBCol>
        <MDBCol size="3" sm="3">
          <MDBIcon fas icon="calendar-alt" style={{ marginRight: 15 }} />Phase Name
        </MDBCol>
        <MDBCol size="3" sm="9">
          {taskDetailInfo?.phaseName}
        </MDBCol>
        <MDBCol size="3" sm="3">
          <MDBIcon
            fas
            icon="tasks"
            style={{ marginRight: 15 }}
          />
          Task Name
        </MDBCol>
        {renderTaskName(totalSub, totalDone, taskDetailInfo?.name)}
      </MDBRow>

      <DisplayTaskProcessing
        statusName={taskDetailInfo?.statusName}
        planHours={taskDetailInfo?.estimateHours}
        workingHours={taskDetailInfo?.normalHours ?? 0}
        otHours={taskDetailInfo?.overTimeHours ?? 0}
        displayOverHours
      />

      <MDBTextArea
        className="mb-3"
        id="textAreaDescription"
        rows="{5}"
        style={{ marginTop: 20 }}
        value={description ?? ""}
        readOnly
        disabled
      /></>
  }

  return (
    <div>
      <MDBRow style={{ textAlign: "right", margin: 10 }}>
        <MDBCol size="3" sm="10"></MDBCol>
        <MDBCol size="3" sm="2">
          <MDBIcon fas icon="hand-point-left" style={{ textAlign: "right", margin: 10 }} onClick={() => navigate(-1)} />
        </MDBCol>
      </MDBRow>

      <MDBAccordion alwaysOpen initialActive={0} title={taskDetailInfo?.description}>
        <MDBAccordionItem collapseId={1} headerTitle={renderTitleTask()}>
          <MDBRow style={{ textAlign: "left" }}>
            <MDBCol size="3" sm="6">
              {renderMainTask()}
            </MDBCol>
            <MDBCol size="3" sm="6">
              {renderBarEstimate()}
            </MDBCol>
          </MDBRow>
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle="Task information" title={taskDetailInfo?.description}>
          <MDBInput
            className="mb-3"
            type="text"
            label="Name"
            title="Name of task"
            value={taskName ?? ""}
            onChange={(evt) => setTaskName(evt.target.value)}
          />

          <MDBRow style={{ textAlign: "left" }}>
            <MDBCol size="3" sm="8">
              {renderBasicInfo()}
              <MDBTextArea
                className="mb-3"
                label="Description"
                id="textAreaDescription"
                rows="{5}"
                style={{ marginTop: 20 }}
                value={description ?? ""}
                onChange={(evt) => setDescription(evt.target.value)}
              />
            </MDBCol>
            <MDBCol size="3" sm="4">
              {renderTeamByUserRole()}
            </MDBCol>
          </MDBRow>
          {divCtrls()}
        </MDBAccordionItem>
        {renderAccordionSubTasks()}
        {renderRevisionTasks()}
      </MDBAccordion>

      {isLogTimeOpen && (
        <LogWork
          taskInfo={taskDetailInfo}
          subTasks={(taskDetailInfo?.subTasks ?? []).filter((it) => it?.statusName !== "Done")}
          onCloseModal={() => setIsLogTimeOpen(false)}
          saveData={(data) => saveLogTime(data)}
        />
      )}

      {isOpenSubTaskModal && (
        <CreateNewSubTask
          taskId={taskDetailInfo?.id}
          teamId={teamId}
          taskName={taskDetailInfo?.name ?? ""}
          isPM={taskDetailInfo?.isPM}
          staffs={projectResources ?? []}
          projectResources={projectResources ?? []}
          onCloseModal={() => setIsOpenSubTaskModal(false)}
          onSave={(data) => onSaveSubTask(data)}
        />
      )}

      {isRevision && <RevisionTaskInfo
        teams={(curTeams ?? []).filter((item) => +item.status === 1 || item.isActive)}
        userRole={userRole}
        taskDetailInfo={taskDetailInfo}
        taskInfo={taskDetailInfo}
        onCloseModal={() => setIsRevision(false)}
        onSave={(data) => onAddRevisionTask(data)} />}

      <ToastContainer />
    </div>
  );
}

export default PageViewTask;
