import {
  MDBCol,
  MDBRow,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn,
  MDBInput,
  MDBIcon
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { configHeader } from "../../utils/tokenHelper";
import { orderBy } from "lodash";
import { dateFormatDMY, userRoles } from "../../utils/utilities";
import DatePicker from "react-datepicker";

function ViewPhaseMembers({ projectId, userRole, phaseInfo, publishing, reloadPublishing }) {

  const [phasePublishing, setPhasePublishing] = useState(publishing ?? []);
  const [tabActive, setTabActive] = useState('tabStaff');
  const [publishDate, setPublishDate] = useState(new Date());
  const [publishName, setPublishName] = useState('');

  useEffect(() => {
    setPhasePublishing(publishing ?? [])
  }, [publishing]);

  // useEffect(() => {
  //   reloadPublishing(phaseInfo?.id)
  // }, [phaseInfo]);

  // const reloadPublishing = (phaseId) => {
  //   axios
  //     .get(
  //       `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseId}/Publishing`,
  //       configHeader()
  //     )
  //     .then((result) => {
  //       setPhasePublishing(result?.data ?? []);
  //     });
  // };

  const handleTabClick = (value) => {
    if (value === tabActive) {
      return;
    }

    setTabActive(value);
  };

  const saveData = () => {
    let data = {
      phaseId: phaseInfo?.id,
      projectId: projectId,
      name: publishName,
      publishDate: publishDate
    };

    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${phaseInfo?.id}/Publishing`,
        data,
        configHeader()
      )
      .then(() => {
        reloadPublishing(phaseInfo?.id)
      });
  }

  const handleDeletePublish = (id) => {
    axios
      .delete(
        `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/Publishings/${id}`,
        configHeader()
      )
      .then(() => {
        reloadPublishing(phaseInfo?.id)
      });
  }

  const renderNewPublishing = (hasPermission) => {
    if (!hasPermission) return <></>
    return <MDBRow style={{ textAlign: "left", marginBottom: 15 }}>
      <MDBCol sm="6" >
        <MDBInput
          className="mb-3"
          type="text"
          label="Publish Name"
          value={publishName}
          style={{ padding: 5 }}
          onChange={(evt) => setPublishName(evt.target.value)}
        />
      </MDBCol>
      <MDBCol sm="4" >
        <DatePicker
          selected={publishDate}
          onChange={(date) => setPublishDate(date)}
        />
      </MDBCol>
      <MDBCol sm="2">
        <MDBBtn disabled={(publishName ?? "").length < 1} onClick={() => saveData()}>Publish</MDBBtn>
      </MDBCol>
    </MDBRow>
  }

  const renderPublishing = (items) => {
    let hasPermission = userRole === userRoles.Admin ?? items[0]?.isPM ?? false;
    if ((items ?? []).length < 1) return <>
      {renderNewPublishing(hasPermission)}
      <MDBRow>
        <MDBCol sm="1"></MDBCol>
        <MDBCol sm="10">Not yet assign any plan publish</MDBCol>
      </MDBRow>
    </>

    return <>
      {renderNewPublishing(hasPermission)}
      {orderBy(items ?? [], "publishDate").map((item) => {
        return <MDBRow>
          <MDBCol sm="6" style={{ textAlign: "left" }}>
            {item?.name}
          </MDBCol>
          <MDBCol sm="4">{dateFormatDMY(item?.publishDate)}</MDBCol>
          <MDBCol sm="2">
            <MDBIcon
              fas
              icon="trash-alt" color="warning"
              style={{ cursor: "pointer", marginRight: 10 }}
              title={`Delete Publish ${item?.name}`}
              onClick={() => handleDeletePublish(item?.id)}
            />
          </MDBCol>
        </MDBRow>
      })}
    </>
  }

  const renderPhaseInfo = () => {
    return <>
      <MDBTabs justify className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleTabClick('tabStaff')} active={tabActive === 'tabStaff'}>
            Members ({phaseInfo?.staffs?.length})
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleTabClick('tabPublish')} active={tabActive === 'tabPublish'}>
            Publishing ({phasePublishing?.length})
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={tabActive === 'tabStaff'}>
          {renderStaffs(orderBy(phaseInfo?.staffs ?? [], "teamName"))}
        </MDBTabsPane>
        <MDBTabsPane open={tabActive === 'tabPublish'}>
          {renderPublishing(phasePublishing ?? [])}
        </MDBTabsPane>
      </MDBTabsContent>

    </>
  }

  const renderStaffs = (staffs) => {
    if ((staffs ?? []).length < 1) return <MDBRow>
      <MDBCol sm="1"></MDBCol>
      <MDBCol sm="10">Not yet assign any staffs</MDBCol>
    </MDBRow>

    return (staffs ?? []).map((item) => {
      return <MDBRow>
        <MDBCol sm="1"></MDBCol>
        <MDBCol sm="6" style={{ textAlign: "left" }}>
          {item?.displayName}
        </MDBCol>
        <MDBCol sm="4">{item?.teamName}</MDBCol>
      </MDBRow>
    })
  }

  return renderPhaseInfo();
}

export default ViewPhaseMembers;
