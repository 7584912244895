import React, { } from "react";

import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBNavbarItem,
  MDBNavbarLink,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

function AccountantMenu() {
  const navigate = useNavigate();
  return (
    <>
      <MDBNavbarItem>

        <MDBDropdown style={{ backgroundColor: "white" }}>
          <MDBDropdownToggle tag="a" className="nav-link" role="button">
            <MDBIcon fas icon="money-bill-alt" style={{ marginRight: 15 }} />
            Bills
          </MDBDropdownToggle>
          <MDBDropdownMenu light={true}>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/receivables")}>
              <MDBNavbarLink onClick={() => navigate("/receivables")}>
                <MDBIcon fas icon="cash-register" style={{ marginRight: 15 }} />
                Receivables
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/bills")}>
              <MDBNavbarLink onClick={() => navigate("/bills")}>
                <MDBIcon fas icon="money-bill-alt" style={{ marginRight: 15 }} />
                List of Bills
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/contracts")}>
              <MDBNavbarLink onClick={() => navigate("/contracts")}>
                <MDBIcon
                  fas
                  icon="file-signature"
                  style={{ marginRight: 15 }}
                />
                List of Contracts
              </MDBNavbarLink>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarItem>
      <MDBNavbarItem>
        <MDBDropdown style={{ backgroundColor: "white" }}>
          <MDBDropdownToggle tag="a" className="nav-link" role="button">
            <MDBIcon fas icon="th" style={{ marginRight: 15 }} />
            Settings
          </MDBDropdownToggle>
          <MDBDropdownMenu light={true}>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/companies")}>
              <MDBNavbarLink onClick={() => navigate("/companies")}>
                <MDBIcon
                  fas
                  icon="building"
                  style={{ marginRight: 15 }}
                />
                List of Customers
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/budgets")}>
              <MDBNavbarLink onClick={() => navigate("/budgets")}>
                <MDBIcon
                  fas
                  icon="money-check-alt"
                  style={{ marginRight: 15 }}
                />
                List of Budgets
              </MDBNavbarLink>
            </MDBDropdownItem>
            <MDBDropdownItem divider />
            <MDBDropdownItem onClick={() => navigate("/receipt-settings")}>
              <MDBNavbarLink onClick={() => navigate("/receipt-settings")}>
                <MDBIcon fas icon="users" style={{ marginRight: 15 }} />
                Receipt Settings
              </MDBNavbarLink>
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarItem>
    </>
  );
}

export default AccountantMenu;
