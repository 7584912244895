import { useEffect, useMemo, useState } from "react";
import { MDBAccordion, MDBAccordionItem, MDBBadge, MDBCol, MDBIcon, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import axios from "axios";
import { configHeader } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import CurrentLogTimeStatus from "./CurrentLogTimeStatus";
import CreateNewSubTask from "../../modals/CreateNewSubTask";
import ViewTeamMembers from "../../modals/ViewTeamMembers";
import LogWork from "./LogWork";
import UpdateTaskInfo from "../../modals/UpdateTaskInfo";
import ConfirmDeleteDialog from "../../components/ConfirmDialog";
import "./Project.css";
import DisplayTaskProcessing from "../Planning/DisplayTaskProcessing";
import ViewSubTasks from "../../modals/ViewSubTasks";
import { useNavigate } from "react-router-dom";
import RevisionTaskInfo from "../../modals/RevisionTaskInfo";
import ViewBoardItem from "../TaskInfo/ViewBoardItem";
import { dateFormatDMY, dateFormatShortDMY, defaultBoxMinWidth, defaultBoxWidth, userRoles } from "../../utils/utilities";
import DisplayOverdue from "../../components/DisplayOverdue";
import CtrlDisplayExtentDetail from "../../components/CtrlDisplayExtentDetail";
import DisplayMultiRows from "../../components/DisplayMultiRows";
import { first } from "lodash";

function CellSection({
  colIndex,
  taskIndex,
  cellInfo,
  teams,
  userRole,
  staffsOfPhase,
  projectResources,
  onUpdateTask,
  handleDeleteTask,
  reloadPhaseInfo,
  currentPhaseId,
  handleLogWork,
  onAddRevisionTask,
  colWidth,
  handleViewWidth
}) {

  const taskInfo = useMemo(() => cellInfo ?? {}, [cellInfo]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [taskDetailInfo, setTaskDetailInfo] = useState();
  const [isOpenTeamMembers, setIsOpenTeamMembers] = useState(false);
  const [isOpenSubTaskModal, setIsOpenSubTaskModal] = useState(false);
  const [teamId, setTeamId] = useState();
  const [teamName, setTeamName] = useState();
  const [userTeamId, setUserTeamId] = useState();
  const [canModify, setCanModify] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [taskInfoId, setTaskInfoId] = useState(cellInfo?.id);
  const [subTasks, setSubTasks] = useState();
  const [curTeams, setCurTeams] = useState(teams ?? []);
  const [showSubTask, setShowSubTask] = useState(false);
  const [isLogTimeOpen, setIsLogTimeOpen] = useState(false);
  const [isRevision, setIsRevision] = useState(false);
  const [hasRevision, setHasRevision] = useState(false);
  const [initialItem, setInitialItem] = useState(0);
  const [modalWidth, setModalWidth] = useState(colWidth);

  const navigate = useNavigate();

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserTeamId(infoObj.teamId);
    if (userRole === userRoles.Admin) {
      setCanModify(true);
    }

    reloadAllTeams();
  }, []);

  useEffect(() => {
    setModalWidth(colWidth);
  }, [colWidth]);

  useEffect(() => {
    setTaskInfoId(taskInfo?.id);
    if (taskInfo?.isPM) setCanModify(true);
  }, [taskInfo]);

  useEffect(() => {
    reloadTaskDetailInfo(taskInfoId);
    reloadAllTeams();
  }, [taskInfoId]);

  const reloadAllTeams = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/Teams`,
        configHeader()
      )
      .then((result) => {
        let dataResults = result?.data?.dataResults ?? []
        setCurTeams(dataResults);
      });
  }

  const reloadTaskDetailInfo = (taskInfoId) => {
    taskInfoId &&
      axios
        .get(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${taskInfoId}`,
          configHeader()
        )
        .then((result) => {
          let taskInfo = result?.data
          setTaskDetailInfo(taskInfo);
          setHasRevision(taskInfo?.hasRevision);
          setSubTasks(taskInfo?.subTasks);
          let statusDone = taskInfo?.statusName === "Done"
          setIsDone(statusDone)
        });
  };

  const saveData = (data) => {
    taskInfo?.id &&
      axios
        .post(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${taskInfo.id}/LogWork`,
          data,
          configHeader()
        )
        .then(() => {
          handleLogWork(taskInfo?.id, data);
          reloadTaskDetailInfo();
          reloadPhaseInfo(currentPhaseId);
          setIsLogTimeOpen(false);
          setIsModalOpen(false);
        });
  };

  const handleOnDrag = (e) => {
    e.dataTransfer.setData(
      "text",
      JSON.stringify({ taskIndex, prevColIndex: colIndex })
    );
  };

  const handleOpenSubTask = (teamId) => {
    setIsOpenSubTaskModal(true);
    setTeamId(teamId);
  };

  const handleOpenUsersOfSubTask = (teamId, teamName) => {
    setIsOpenTeamMembers(true);
    setTeamId(teamId);
    setTeamName(teamName);
  };

  const onSaveSubTask = (data) => {
    taskInfoId &&
      axios
        .post(
          `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${taskInfoId}/Subtask`,
          data,
          configHeader()
        )
        .then(() => {
          reloadTaskDetailInfo(taskInfoId);
          setIsOpenSubTaskModal(false);
          reloadPhaseInfo(currentPhaseId);
        });
  };

  const renderCtrDelete = () => {
    if (userRole === userRoles.Admin || taskInfo?.isPM) {
      return (
        <MDBRow>
          <MDBCol sm="12" title="Delete this Task">
            <div
              style={{ cursor: "pointer", alignItems: "flex-end" }}
              onClick={() => {
                setIsConfirmDelete(true);
              }}
            >
              <MDBIcon fas icon="trash-alt" color="warning" />
            </div>
          </MDBCol>
        </MDBRow>
      );
    }

    return <></>;
  };

  const renderLogWork = () => {
    let hasTeam = (taskDetailInfo?.teamIds ?? [])
      .filter((item) => +userTeamId === +item)
      ?.length > 0;

    if (!hasTeam && userRole !== userRoles.Admin && !taskInfo?.isPM) return <></>;
    if (hasRevision) return <></>;

    let statusDone = taskInfo?.statusName === "Done";
    if (statusDone) return <>
      <div onClick={() => setIsRevision(true)}>
        <MDBIcon fas icon="dna" style={{ cursor: "pointer", marginTop: 20 }} /> Revision
      </div>
    </>;

    if (subTasks?.length < 1) return <></>;

    return <MDBRow>
      <MDBCol size="9">
        <div style={{ cursor: "pointer", marginTop: 15 }}
          onClick={() => setIsLogTimeOpen(true)}>
          Log Working
        </div>
      </MDBCol>
      <MDBCol size="3">
        <MDBIcon
          fas
          icon="eye"
          title="View subtasks"
          style={{ cursor: "pointer", marginTop: 20 }}
          onClick={() => setShowSubTask(true)}
        />
      </MDBCol>
    </MDBRow>;
  };

  const handleMinTaskDetail = () => {
    setModalWidth(defaultBoxMinWidth);
  }

  const handleViewTaskDetail = () => {
    setInitialItem(1);
    handleViewWidth();
    setModalWidth(defaultBoxWidth);
  }

  const renderTeamViewInfo = (item, isDone, title, hasSubTasks) => {

    let teamReviews = (taskDetailInfo?.teamReviews ?? []).filter((it) => +item?.id === it.teamId);
    let teamReview = first(teamReviews) ?? {};

    if ((teamReview?.reviewerId ?? 0) > 0) {
      return <MDBRow title={item?.name}>
        <MDBCol size="3" sm="8" className=" truncate max-w-[240px]">
          <MDBIcon fas icon="check-circle"
            title={`Reviewer ${teamReview?.userReviewer} (${teamReview?.reviewer})`}
            style={{ marginRight: 10 }} />
          <MDBBadge pill light title={"Subtasks has been completed"}>
            {title}
          </MDBBadge>
          {item?.name}
        </MDBCol>
        <MDBCol size="3" sm="2" title={"View current sub tasks"}>
          {hasSubTasks?.length > 0 && <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => handleOpenUsersOfSubTask(item?.id, item?.name)}
          >
            <MDBIcon fas icon="eye" />
          </div>}
        </MDBCol>
        <MDBCol size="3" sm="2" title={"Add new sub task"}>
          {!isDone && <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => handleOpenSubTask(item?.id)}
          >
            <MDBIcon fas icon="plus" />
          </div>}
        </MDBCol>
      </MDBRow >
    }

    return <MDBRow title={item?.name}>
      <MDBCol size="3" sm="8" className=" truncate max-w-[240px]">
        <MDBBadge pill light title={"Subtasks has been completed"}>
          {title}
        </MDBBadge>
        {item?.name}
      </MDBCol>
      <MDBCol size="3" sm="2" title={"View current sub tasks"}>
        {hasSubTasks?.length > 0 && <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => handleOpenUsersOfSubTask(item?.id, item?.name)}
        >
          <MDBIcon fas icon="eye" />
        </div>}
      </MDBCol>
      <MDBCol size="3" sm="2" title={"Add new sub task"}>
        {!isDone && <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => handleOpenSubTask(item?.id)}
        >
          <MDBIcon fas icon="plus" />
        </div>}
      </MDBCol>
    </MDBRow >
  }

  const renderSubTasksByTeamInfo = (myTeams) => {
    return (myTeams ?? []).map((item) => {
      let isChecked = +userTeamId === +item?.id ||
        (taskDetailInfo?.teamIds ?? []).filter((it) => it === item.id)
          ?.length > 0;

      let hasSubTasks = (subTasks ?? []).filter((t) => t.teamId === +item?.id);
      let subsCompleted = (hasSubTasks ?? []).filter((it) => it.isCompleted)?.length;
      let title = hasSubTasks?.length > 0 ? ` ${subsCompleted}/${hasSubTasks?.length}` : "";
      return isChecked ? renderTeamViewInfo(item, isDone, title, hasSubTasks) : "";
    })
  }

  const [basicActive, setBasicActive] = useState('tabSummary');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  const renderTeamRevier = (info) => {
    if ((info?.reviewerId ?? 0) < 1) return <></>
    let myTeams = (curTeams ?? []).filter((item) => info?.teamId === +item?.id);
    let myTeam = first(myTeams);

    return <MDBRow>
      <MDBCol size="3" sm="4" className={` truncate max-w-[${colWidth ?? 215}px] `} title={myTeam?.name}>
        {myTeam?.name}
      </MDBCol>
      <MDBCol size="3" sm="4" className={` truncate max-w-[${colWidth ?? 215}px] `}
        title={`${info?.userReviewer} (${info?.reviewer})`}>
        {`${info?.userReviewer} (${info?.reviewer})`}
      </MDBCol>
      <MDBCol size="3" sm="4" title={dateFormatDMY(info?.reviewDate)}>
        {dateFormatShortDMY(info?.reviewDate)}
      </MDBCol>
    </MDBRow>
  }

  const renderReviewTeams = () => {
    let items = (taskDetailInfo?.teamReviews ?? []).filter((info) => (info?.reviewerId ?? 0) > 0);
    if ((items ?? []).length < 1) return <h5>Not yet any team review or approval.</h5>

    return (items ?? []).map((teamReview) => {
      return renderTeamRevier(teamReview);
    })
  }

  const divTeams = () => {
    if (curTeams?.length < 1) return <></>

    let myTeams = (curTeams ?? []).filter((item) => +userTeamId === +item?.id);
    if (userRole === userRoles.Admin || taskInfo?.isPM) {
      myTeams = (curTeams ?? [])
    }

    if ((modalWidth ?? defaultBoxMinWidth) <= defaultBoxMinWidth) {
      return <MDBAccordionItem collapseId={20 + (taskInfo?.id ?? 0)} headerTitle="Teams">
        {renderSubTasksByTeamInfo(myTeams)}
      </MDBAccordionItem>
    }

    if (myTeams?.length > 0) {
      return <MDBAccordionItem collapseId={20 + (taskInfo?.id ?? 0)} headerTitle="Teams">
        <MDBTabs className='mb-3'>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick(`tabSummary`)} active={basicActive === `tabSummary`}>
              Team Info
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick(`tabReview`)} active={basicActive === `tabReview`}>
              Review Info
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>

        <MDBTabsContent>
          <MDBTabsPane open={basicActive === `tabSummary`}>
            {renderSubTasksByTeamInfo(myTeams)}
          </MDBTabsPane>
          <MDBTabsPane open={basicActive === `tabReview`}>
            {renderReviewTeams()}
          </MDBTabsPane>
        </MDBTabsContent>
      </MDBAccordionItem >
    }

    return <></>
  }

  const renderTitleTaskDisplayInfo = () => {
    if ((modalWidth ?? defaultBoxMinWidth) <= defaultBoxMinWidth) {
      return <MDBRow title={cellInfo?.description}>
        <MDBCol sm="6" className={` truncate max-w-[${colWidth ?? 215}px] `} title={`View detail task ${cellInfo?.name}`}
        >
          <div style={{ marginTop: -5 }} >
            <DisplayTaskProcessing
              statusName={taskDetailInfo?.statusName}
              planHours={taskDetailInfo?.estimateHours}
              workingHours={taskDetailInfo?.normalHours ?? 0}
              otHours={taskDetailInfo?.overTimeHours ?? 0}
              displayOverHours
            />
          </div>
        </MDBCol>
        <MDBCol sm="2" title={`Update task information: ${cellInfo?.name}`}>
          {canModify && <div
            style={{ cursor: "pointer", alignItems: "flex-end" }}
            onClick={() => setIsModalOpen(true)}
          >
            <MDBIcon fas icon="user-edit" />
          </div>}
        </MDBCol>
        <MDBCol sm="2">{renderCtrDelete()}</MDBCol>
        <MDBCol sm="2">
          <CtrlDisplayExtentDetail
            tabWidth={modalWidth}
            handleViewTaskDetail={() => handleViewTaskDetail()}
            handleMinTaskDetail={() => handleMinTaskDetail()}
          />
        </MDBCol>
      </MDBRow>
    }

    return <MDBRow title={cellInfo?.description}>
      <MDBCol sm="9" className={` truncate max-w-[${colWidth ?? 215}px] `}
        title={`View detail task ${cellInfo?.name}`}
      >
        <div style={{ marginTop: -5 }} >
          <DisplayTaskProcessing
            statusName={taskDetailInfo?.statusName}
            planHours={taskDetailInfo?.estimateHours}
            workingHours={taskDetailInfo?.normalHours ?? 0}
            otHours={taskDetailInfo?.overTimeHours ?? 0}
            displayOverHours
          />
        </div>
      </MDBCol>
      <MDBCol sm="1" title={`Update task information: ${cellInfo?.name}`}>
        {canModify && <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => setIsModalOpen(true)}
        >
          <MDBIcon fas icon="user-edit" />
        </div>}
      </MDBCol>
      <MDBCol sm="1">{renderCtrDelete()}</MDBCol>
      <MDBCol sm="1">
        <CtrlDisplayExtentDetail
          tabWidth={modalWidth}
          handleViewTaskDetail={() => handleViewTaskDetail()}
          handleMinTaskDetail={() => handleMinTaskDetail()}
        />
      </MDBCol>
    </MDBRow>
  }

  const renderCurrentStatus = () => {
    let titleTask = (subTasks ?? []).length < 1 ? "" : ` (${(subTasks ?? []).length} SubTasks)`;
    if (modalWidth !== defaultBoxWidth) return <>{titleTask}</>

    return <>
      <div style={{ marginRight: 25 }}></div>
      <CurrentLogTimeStatus
        statusName={taskDetailInfo?.statusName}
        estimateHours={taskDetailInfo?.estimateHours}
        normalHours={(taskDetailInfo?.normalHours ?? 0) + (taskDetailInfo?.curNormalHours ?? 0)}
        overTime={(taskDetailInfo?.overTimeHours ?? 0) + (taskDetailInfo?.curOverTimeHours ?? 0)}
        startDate={taskDetailInfo?.startDate}
        endDate={taskDetailInfo?.endDate}
        hideStatus
        hideDate
      />
      <div style={{ marginLeft: 25 }}>{titleTask}</div>
    </>
  }

  const renderCurrentDescription = () => {
    if (modalWidth !== defaultBoxWidth) return <></>
    return <DisplayMultiRows description={cellInfo?.description ?? ""} />
  }

  const renderControlTaskAccordion = () => {
    let initId = (modalWidth ?? defaultBoxMinWidth) < defaultBoxMinWidth ? 0 : initialItem;
    return <MDBAccordion alwaysOpen initialActive={initId} title={cellInfo?.description}>
      <MDBAccordionItem collapseId={1} headerTitle={
        <>
          <DisplayOverdue
            statusName={cellInfo?.statusName ?? ""}
            endDate={cellInfo?.endDate}
          />
          <div className={` truncate max-w-[${modalWidth ?? 215}px] `} >{cellInfo?.name} </div>
          {renderCurrentStatus()}
        </>
      }
        className={` truncate max-w-[${modalWidth ?? 275}px] `} title={cellInfo?.name}>
        <p className=" font-bold tracking-wide " title={cellInfo?.description}>
          {renderTitleTaskDisplayInfo()}
        </p>
        <MDBAccordion borderless alwaysOpen initialActive={10}>
          <MDBAccordionItem collapseId={10} headerTitle="Task info" title={cellInfo?.description}>
            {modalWidth === defaultBoxMinWidth && <CurrentLogTimeStatus
              statusName={taskDetailInfo?.statusName}
              estimateHours={taskDetailInfo?.estimateHours}
              normalHours={taskDetailInfo?.normalHours ?? 0}
              overTime={taskDetailInfo?.overTimeHours ?? 0}
              startDate={cellInfo?.startDate}
              endDate={cellInfo?.endDate}
              description={cellInfo?.description}
              hideStatus
            />}
            {renderCurrentDescription()}
          </MDBAccordionItem>
          {divTeams()}

          <MDBRow>
            <MDBCol sm="10">
              {renderLogWork()}
            </MDBCol>
            <MDBCol sm="2">
              <MDBIcon fas icon="arrows-alt"
                style={{ cursor: "pointer", marginTop: 20 }}
                title={`View task ${cellInfo?.name}`}
                onClick={() => handleNewTaskDetail()}
              />
            </MDBCol>
          </MDBRow>
        </MDBAccordion>
      </MDBAccordionItem>
    </MDBAccordion>
  }

  const handleNewTaskDetail = () => {
    let viewTaskDetail = {
      taskId: taskInfo?.id,
      phaseId: currentPhaseId
    };
    window.localStorage.setItem("viewTaskDetail", JSON.stringify(viewTaskDetail));
    navigate(`/tasks/${taskInfoId ?? taskInfo?.id ?? 0}`);
  }

  return (
    <div>
      {isConfirmDelete && taskInfo?.id && (
        <ConfirmDeleteDialog
          openModal={true}
          titleDelete={`Delete task ${taskInfo?.name}`}
          onCloseModal={() => setIsConfirmDelete(false)}
          onDelete={() => {
            handleDeleteTask(taskInfo?.id);
            setIsConfirmDelete(false);
          }}
        />
      )}

      <ViewBoardItem
        ctrlAccordion={renderControlTaskAccordion()}
        id={`task-${colIndex}-${taskDetailInfo?.id}`}
        handleOnDrag={handleOnDrag}
        colWidth={colWidth}
      />

      {isRevision && <RevisionTaskInfo
        teams={(curTeams ?? []).filter((item) => +item.status === 1 || item.isActive)}
        userRole={userRole}
        taskDetailInfo={taskDetailInfo}
        taskInfo={taskInfo}
        onCloseModal={() => setIsRevision(false)}
        onSave={(data) => {
          setHasRevision(true);
          onAddRevisionTask(currentPhaseId, data);
        }} />}

      {isModalOpen && canModify && (
        <UpdateTaskInfo
          teams={(curTeams ?? []).filter((item) => +item.status === 1 || item.isActive)}
          userRole={userRole}
          taskDetailInfo={taskDetailInfo}
          taskInfo={taskInfo}
          onCloseModal={() => setIsModalOpen(false)}
          onSave={(data) => onUpdateTask(currentPhaseId, data)}
        />
      )}

      {isLogTimeOpen && (
        <LogWork
          taskInfo={taskInfo}
          subTasks={(taskDetailInfo?.subTasks ?? []).filter((it) => it?.statusName !== "Done")}
          onCloseModal={() => setIsLogTimeOpen(false)}
          saveData={(data) => saveData(data)}
        />
      )}
      {isOpenTeamMembers && (
        <ViewTeamMembers
          taskId={cellInfo?.id}
          teamId={teamId}
          teamName={teamName}
          taskName={cellInfo?.name}
          taskDetailInfo={taskDetailInfo}
          onCloseModal={() => setIsOpenTeamMembers(false)}
        />
      )}

      {showSubTask && (
        <ViewSubTasks
          taskId={cellInfo?.id}
          taskName={cellInfo?.name}
          taskDetailInfo={taskDetailInfo}
          onCloseModal={() => setShowSubTask(false)}
        />
      )}

      {isOpenSubTaskModal && (
        <CreateNewSubTask
          taskId={cellInfo?.id}
          teamId={teamId}
          taskName={cellInfo?.name}
          isPM={taskInfo?.isPM}
          staffs={staffsOfPhase ?? []}
          projectResources={projectResources}
          onCloseModal={() => setIsOpenSubTaskModal(false)}
          onSave={(data) => onSaveSubTask(data)}
        />
      )}
    </div>
  );
}

export default CellSection;
