import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { MDBBtn, MDBCol, MDBIcon, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import { AgGridReact } from "ag-grid-react";
import UpdateDisplayName from "../Planning/UpdateDisplayName";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { orderBy } from "lodash";
import UpdateRoleName from "./UpdateRoleName";
import { userRoles } from "../../utils/utilities";
import UpdatePrice from "../Planning/UpdatePrice";
import CreateUser from "./CreateUser";
import ViewUserInfo from "./ViewUserInfo";
import ConfirmDeleteDialog from "../../components/ConfirmDialog";

function UserMembers() {
  const navigate = useNavigate();
  const [staffs, setStaffs] = useState();
  const [userId, setUserId] = useState();
  const [userRole, setUserRole] = useState();
  const [uRole, setURole] = useState();
  const [myId, setMyId] = useState();
  const [myTeamId, setMyTeamId] = useState();

  const [displayName, setDisplayName] = useState();
  const [openChangeNameModal, setOpenChangeNameModal] = useState(false);
  const [openRoleName, setOpenRoleName] = useState(false);
  const [openNewUser, setOpenNewUser] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [userPrice, setUserPrice] = useState();
  const [isDeleteUser, setIsDeleteUser] = useState(false);
  const [archivedUsers, setArchivedUsers] = useState([]);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Manage users";
  }, []);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setURole(infoObj.role);
    setMyId(infoObj.id);
    setMyTeamId(+infoObj.teamId);
    loadDataStaffs(infoObj?.role, infoObj?.teamId);
    loadDataArchivedUsers(infoObj?.role);
  }, []);

  const loadDataArchivedUsers = (uRole) => {
    let endpoint = `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Archived-Users`;
    if (uRole !== userRoles.Admin) {
      return;
    }

    axios
      .get(endpoint, configHeader())
      .then((result) => {
        setArchivedUsers(orderBy(result?.data));
      });
  };

  const loadDataStaffs = (uRole) => {
    let endpoint = `${apiEndpoint.hosting}/Teams/My-Team`;
    if (uRole === userRoles.Admin) endpoint = `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/All-Users`;

    axios
      .get(endpoint, configHeader())
      .then((result) => {
        setStaffs(orderBy(result?.data, "isActive", "desc"));
      });
  };


  const handleEditName = (id, name) => {
    setUserId(id);
    setDisplayName(name);
    setOpenChangeNameModal(true);
  };

  const handleDeleteUser = (id, name) => {
    setUserId(id);
    setDisplayName(name);
    setIsDeleteUser(true);
  };

  const handleDelete = () => {
    axios
      .delete(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/${userId}`,
        configHeader()
      )
      .then(() => {
        setIsDeleteUser(false);
        loadDataStaffs(uRole, myTeamId);
        loadDataArchivedUsers(uRole);
      });
  }

  const handlePrice = (id, displayName, pricing) => {
    setUserId(id);
    setDisplayName(displayName);
    setUserPrice(pricing);
    setOpenPriceModal(true);
  };

  const updateDisplayName = () => {
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Staffs/${userId}/UpdateName`,
        { name: displayName },
        configHeader()
      )
      .then(() => {
        loadDataStaffs(uRole, myTeamId);
        setOpenChangeNameModal(false);
      });
  };

  const handleActive = (id, name) => {
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Staffs/${id}/Active`,
        {},
        configHeader()
      )
      .then(() => {
        loadDataStaffs(uRole, myTeamId);
        toast.success(`Your request active ${name} was successful.`);
      });
  };

  const handleBlockUser = (id, name) => {
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Staffs/${id}/Disable`,
        {},
        configHeader()
      )
      .then(() => {
        loadDataStaffs(uRole, myTeamId);
        toast.success(
          `Account of ${name} account has been disabled. The account can't log in to system.`
        );
      });
  };

  const handleResetPass = (id, name) => {
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Staffs/${id}/Reset-password`,
        {},
        configHeader()
      )
      .then(() => {
        toast.success(
          `The user ${name} has been reset password to default password.`
        );
      });
  };

  const handleRestoreUser = (id, name) => {
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/${id}/Restore`,
        {},
        configHeader()
      )
      .then(() => {
        loadDataStaffs(uRole);
        loadDataArchivedUsers(uRole);
        toast.success(
          `The user ${name} has been successfully restored`
        );
      });
  };

  const handleChangeRole = (id, name, role) => {
    setOpenRoleName(true);
    setUserId(id);
    setUserRole(role);
    setDisplayName(name);
  };

  const createUser = (data) => {
    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}`,
        data,
        configHeader()
      )
      .then(() => {
        setOpenNewUser(false);
        loadDataStaffs(uRole, myTeamId);
        toast.success(
          `The user ${data?.name} has been created successful.`
        );
      });
  };

  const saveChangePrice = () => {
    userId &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Staffs/${userId}/UpdatePrice`,
          { price: userPrice ?? 0 },
          configHeader()
        )
        .then(() => {
          loadDataStaffs(uRole, myTeamId);
          setOpenPriceModal(false);
          toast.success(
            `The user ${displayName} has been changed price successful.`
          );
        });
  };

  const saveChangeRole = (role) => {
    userId &&
      axios
        .put(
          `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/Staffs/${userId}/UpdateRole`,
          { name: role },
          configHeader()
        )
        .then(() => {
          setOpenRoleName(false);
          loadDataStaffs(uRole, myTeamId);
          toast.success(
            `The user ${displayName} has been change role to ${role}.`
          );
        });
  };

  const colUserDefs = [
    {
      field: "displayName",
      cellStyle: {
        textAlign: "left",
      },
      filter: true,
      pinned: 'left',
      lockPinned: true,
      width: 235,
      cellRenderer: (row) => <ViewUserInfo
        roleName={row.data?.roleName}
        isActive={row.data?.isActive}
        displayName={row.data?.displayName} />
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 235,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "team",
      cellStyle: {
        textAlign: "left",
      },
      width: 215,
      filter: true
    },
    {
      field: "roleName",
      headerName: "Role",
      cellStyle: {
        textAlign: "left",
      },
      width: 195,
      filter: true
    },
    {
      field: "teamleader",
      headerName: "Team Leader",
      width: 215,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "actions",
      headerName: "",
      cellStyle: {
        textAlign: "left",
      },
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        if (uRole !== userRoles.Admin && uRole !== userRoles.Teamleader && myId !== row.data.id) return <></>
        return (
          <MDBIcon
            fas
            icon="user-edit"
            title="Change display name"
            onClick={() => handleEditName(row.data.id, row.data.displayName)}
          />
        );
      },
    },
  ];

  const renderCtrlDelete = (id, displayName) => {
    if (uRole !== userRoles.Admin) return <></>
    return (
      <MDBIcon
        fas
        icon="trash-alt" color="warning"
        style={{ cursor: "pointer", marginRight: 10 }}
        title={`Delete user ${displayName}`}
        onClick={() => handleDeleteUser(id, displayName)}
      />
    );
  }

  const renderCtrlChangeRoleName = (roleName, isActive, id, displayName) => {
    if (uRole !== userRoles.Admin) return <></>
    if (!isActive) return <></>;
    return (
      <MDBIcon
        fas
        icon="cogs"
        style={{ cursor: "pointer", marginRight: 10 }}
        onClick={() =>
          handleChangeRole(id, displayName, roleName)
        }
      />
    );
  }

  const renderCtrlRestoreUser = (roleName, id, displayName) => {
    let curAdmin = roleName === userRoles.Admin && myId !== id;
    if (uRole !== userRoles.Admin || curAdmin) return <></>
    return (
      <MDBIcon
        fas icon="undo-alt"
        style={{ cursor: "pointer", marginRight: 10 }}
        onClick={() => handleRestoreUser(id, displayName)}
      />
    );
  }

  const renderCtrlResetPass = (roleName, isActive, id, displayName) => {
    let curAdmin = roleName === userRoles.Admin && myId !== id;
    if (uRole !== userRoles.Admin || curAdmin) return <></>
    if (!isActive) return <></>;
    return (
      <MDBIcon
        fas
        icon="key"
        style={{ cursor: "pointer", marginRight: 10 }}
        onClick={() => handleResetPass(id, displayName)}
      />
    );
  }

  const renderCtrlChangeDisplayName = (isActive, id, displayName) => {
    if (!isActive) return <></>;
    return (
      <MDBIcon
        fas
        icon="user-edit"
        style={{ cursor: "pointer", marginRight: 10 }}
        title="Change display name"
        onClick={() => handleEditName(id, displayName)}
      />
    );
  }

  const renderCtrlChangePrice = (isActive, id, displayName, pricing) => {
    if (uRole !== userRoles.Admin) return <></>
    if (!isActive) return <></>;
    return (
      <MDBIcon
        fas
        icon="money-bill-alt"
        title="Change Price"
        style={{ cursor: "pointer", marginRight: 10 }}
        onClick={() => handlePrice(id, displayName, pricing)}
      />
    );
  }

  const renderCtrlActiveUser = (isActive, id, displayName) => {
    if (isActive)
      return (
        <MDBIcon
          fas
          icon="user-check"
          color="info"
          style={{ cursor: "pointer", marginRight: 10 }}
          title={`Disable your account ${displayName}`}
          onClick={() => handleBlockUser(id, displayName)}
        />
      );

    return (
      <MDBIcon
        fas
        icon="user-alt-slash"
        style={{ cursor: "pointer", marginRight: 10 }}
        title={`Active account of ${displayName}`}
        color="warning"
        onClick={() => handleActive(id, displayName)}
      />
    );
  }

  const colArchiveDefs = [
    {
      field: "displayName",
      cellStyle: {
        textAlign: "left",
      },
      filter: true,
      pinned: 'left',
      lockPinned: true
    },
    {
      field: "userName",
      headerName: "User Name",
      cellStyle: {
        textAlign: "left",
      },
      filter: true,
      width: 195,
    },
    {
      field: "team",
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      filter: true,
      width: 215,
    },
    {
      field: "roleName",
      headerName: "Role",
      cellStyle: {
        textAlign: "left",
      },
      filter: true,
      width: 195,
    }, {
      field: "teamleader",
      headerName: "Team Leader",
      width: 215,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 60,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        if (uRole !== userRoles.Admin) return <></>
        return <>
          {renderCtrlRestoreUser(row.data.roleName, row.data.id, row.data.displayName)}
        </>
      },
    }
  ];

  const colDefs = [
    {
      field: "userName",
      headerName: "User Name",
      cellStyle: {
        textAlign: "left",
      },
      pinned: 'left',
      lockPinned: true,
      filter: true,
      width: 215,
    },
    {
      field: "displayName",
      cellStyle: {
        textAlign: "left",
      },
      width: 235,
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellRenderer: (row) => <ViewUserInfo
        roleName={row.data.roleName}
        isActive={row.data.isActive}
        displayName={row.data.displayName} />
    },
    {
      field: "team",
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      filter: true,
      width: 215,
    },
    {
      field: "roleName",
      headerName: "Role",
      cellStyle: {
        textAlign: "left",
      },
      filter: true,
      width: 195,
    }, {
      field: "teamleader",
      headerName: "Team Leader",
      width: 215,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 195,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        if (uRole !== userRoles.Admin) return <></>
        return <>
          {renderCtrlChangePrice(row.data.isActive, row.data.id, row.data.displayName, row.data.pricing)}
          {renderCtrlActiveUser(row.data.isActive, row.data.id, row.data.displayName)}
          {renderCtrlResetPass(row.data.roleName, row.data.isActive, row.data.id, row.data.displayName)}
          {renderCtrlChangeRoleName(row.data.roleName, row.data.isActive, row.data.id, row.data.displayName)}
          {renderCtrlChangeDisplayName(row.data.isActive, row.data.id, row.data.displayName)}
          {renderCtrlDelete(row.data.id, row.data.displayName)}
        </>

      },
    },
    // {
    //   field: "pricing",
    //   width: 125,
    //   cellStyle: {
    //     textAlign: "right",
    //   },
    //   cellRenderer: (row) => toCommas(row.data.pricing),
    // },
  ];

  const colLeaderDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "userName",
      headerName: "User Name",
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      filter: true,
      width: 145,
    },
    {
      field: "displayName",
      cellStyle: {
        textAlign: "left",
      },
      filter: true,
      cellRenderer: (row) => <ViewUserInfo
        roleName={row.data.roleName}
        isActive={row.data.isActive}
        displayName={row.data.displayName} />
    },
    {
      field: "team",
      cellStyle: {
        textAlign: "left",
      },
      filter: true,
      width: 195,
    },
    {
      field: "roleName",
      headerName: "Role",
      cellStyle: {
        textAlign: "left",
      },
      filter: true,
      width: 145,
    },
    {
      field: "teamleader",
      headerName: "Team Leader",
      width: 215,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "actions",
      headerName: "Active user",
      width: 125,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        if (uRole !== userRoles.Admin && uRole !== userRoles.Teamleader) return <></>
        if (row.data.isActive)
          return (
            <MDBIcon
              fas
              icon="user-check"
              color="info"
              title={`Disable your account ${row.data.displayName}`}
              onClick={() => handleBlockUser(row.data.id, row.data.displayName)}
            />
          );

        return (
          <MDBIcon
            fas
            icon="user-alt-slash"
            title={`Active account of ${row.data.displayName}`}
            color="warning"
            onClick={() => handleActive(row.data.id, row.data.displayName)}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Update Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 125,
      cellRenderer: (row) => {
        if (!row.data.isActive && uRole !== userRoles.Teamleader) return <></>;
        return (
          <MDBIcon
            fas
            icon="user-edit"
            title="Change display name"
            onClick={() => handleEditName(row.data.id, row.data.displayName)}
          />
        );
      },
    }
  ];

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const renderButtonAdd = () => {
    if (uRole !== userRoles.Admin && uRole !== "Teamleader") return "";

    return <MDBRow style={{ marginTop: 10, textAlign: "left" }}>
      <MDBCol sm="12">
        <MDBBtn onClick={() => setOpenNewUser(true)} color="info">
          Add new user
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  }

  const [tabActive, setTabActive] = useState('tabStaff');
  const handleTabClick = (value) => {
    if (value === tabActive) {
      return;
    }

    setTabActive(value);
  };

  const renderBodyByUserRole = () => {

    if (uRole === userRoles.Admin) {
      return <>
        <MDBTabs className='mb-3'>
          {renderButtonAdd()}
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleTabClick('tabStaff')} active={tabActive === 'tabStaff'}>
              Members
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleTabClick('tabArchived')} active={tabActive === 'tabArchived'}>
              Archived Users
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
        <MDBTabsContent>
          <MDBTabsPane open={tabActive === 'tabStaff'}>
            <MDBRow style={{ marginTop: 10 }}>
              <MDBCol size="3" sm="12">
                <div className="ag-theme-quartz" style={{ height: "70vh" }}>
                  <AgGridReact
                    rowData={staffs}
                    columnDefs={colDefs}
                    fullWidthCellRenderer
                    onGridReady={onGridReady}
                    pagination
                    paginationPageSize={10}
                  />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBTabsPane>
          <MDBTabsPane open={tabActive === 'tabArchived'}>
            <MDBRow>
              <MDBCol size="3" sm="12">
                <div className="ag-theme-quartz" style={{ height: "65vh" }}>
                  <AgGridReact
                    rowData={archivedUsers}
                    columnDefs={colArchiveDefs}
                    fullWidthCellRenderer
                    onGridReady={onGridReady}
                    pagination
                    paginationPageSize={10}
                  />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBTabsPane>
        </MDBTabsContent>
      </>
    }

    if (uRole === userRoles.Teamleader) {
      return <>
        {renderButtonAdd()}
        <MDBRow style={{ marginTop: 10 }}>
          <MDBCol size="3" sm="12">
            <div className="ag-theme-quartz" style={{ height: "65vh" }}>
              <AgGridReact
                rowData={staffs}
                columnDefs={colLeaderDefs}
                fullWidthCellRenderer
                onGridReady={onGridReady}
                pagination
                paginationPageSize={10}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </>
    }

    return <MDBRow style={{ marginTop: 10 }}>
      <MDBCol size="3" sm="12">
        <div className="ag-theme-quartz" style={{ height: "65vh" }}>
          <AgGridReact
            rowData={staffs}
            columnDefs={colUserDefs}
            fullWidthCellRenderer
            onGridReady={onGridReady}
            pagination
            paginationPageSize={10}
          />
        </div>
      </MDBCol>
    </MDBRow>
  }

  return (
    <>
      {openChangeNameModal && (
        <UpdateDisplayName
          setIsOpenModal={setOpenChangeNameModal}
          displayName={displayName}
          roleName={userRole}
          setDisplayName={setDisplayName}
          updateDisplayName={updateDisplayName}
        />
      )}

      {openRoleName && (
        <UpdateRoleName
          setIsOpenModal={setOpenRoleName}
          roleName={userRole}
          displayName={displayName}
          saveData={(role) => saveChangeRole(role)}
        />
      )}

      {openPriceModal && (
        <UpdatePrice
          setIsOpenModal={setOpenPriceModal}
          userPrice={userPrice}
          setUserPrice={setUserPrice}
          updatePrice={() => saveChangePrice()}
        />
      )}

      {openNewUser && (
        <CreateUser
          teamId={uRole === userRoles.Admin ? 0 : myTeamId}
          onCloseModal={() => setOpenNewUser(false)}
          onSave={(data) => createUser(data)}
        />
      )}

      {isDeleteUser && <ConfirmDeleteDialog
        openModal={true}
        titleDelete={`Delete user ${displayName}`}
        onCloseModal={() => setIsDeleteUser(false)}
        onDelete={() => handleDelete()}
      />}
      {renderBodyByUserRole()}
      <ToastContainer />
    </>
  );
}

export default UserMembers;
