import { orderBy } from "lodash";
import { MDBCheckbox, MDBCol, MDBRow, MDBSwitch } from "mdb-react-ui-kit";
import { dateFormatDMY, newGuid } from "../../utils/utilities";
import DisplayTaskProcessing from "../Planning/DisplayTaskProcessing";
import DisplayPM from "../../components/DisplayPM";
function ViewProjectInfo({ data, handleCheckPhase, handleSetFavorite }) {

  const renderPhaseInfo = (phaseData, isChecked) => {
    return <MDBRow title={phaseData?.name}>
      <MDBCol size="3" sm="1"></MDBCol>
      <MDBCol size="3" sm="1" style={{ textAlign: "right" }}>
        <MDBSwitch
          id={newGuid()}
          checked={isChecked}
          title="Monitoring"
          onChange={(e) => handleCheckPhase(phaseData?.id, e.target.checked)}
        />
      </MDBCol>
      <MDBCol size="3" sm="9" style={{ textAlign: "left" }}>
        {phaseData?.name}
      </MDBCol>
    </MDBRow>
  }

  const handleChangeChecked = (checked, proId) => {
    handleSetFavorite(proId, checked);
  }

  const renderProjectInfo = (data) => {
    let estHours = (data?.estimateHours ?? 0) > 0 ? ` Plan: ${data?.estimateHours} (hours)` : "";
    return <MDBRow title={`${data?.name} Status: ${data?.statusName} ${estHours}`}>
      <MDBCol size="3" sm="3">
        <DisplayPM userName={data?.assigned} />
      </MDBCol>
      <MDBCol size="3" sm="3">
        <DisplayTaskProcessing
          statusName={data?.statusName}
          planHours={data?.estimateHours ?? 0}
          workingHours={data?.normalHours ?? 0}
          otHours={data?.overTimeHours ?? 0}
          displayOverHours
        />
      </MDBCol>
      <MDBCol size="3" sm="4">
        {`${dateFormatDMY(data?.startDate)} => ${data?.endDate ? dateFormatDMY(data?.endDate) : "TBD"}`}
      </MDBCol>
      <MDBCol size="3" sm="2">
        <MDBCheckbox
          id={`chb${newGuid()}`}
          style={{ cursor: "pointer" }}
          checked={data?.favorite ?? false}
          label="Following"
          onChange={(e) => handleChangeChecked(e.target.checked, data?.id)}
        />
      </MDBCol>

    </MDBRow>
  }

  return <>
    {renderProjectInfo(data)}
    <hr class="hr" />
    {orderBy(data?.phaseInfo ?? [], "priorityNumber").map((phase) => renderPhaseInfo(phase, phase.isMonitoring))}
  </>
}

export default ViewProjectInfo;
