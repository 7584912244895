import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { orderBy, toNumber, uniq } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBBadge, MDBCol, MDBIcon, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import { AgGridReact } from "ag-grid-react";
import { dateFormatDMY, onGridReady, toCommas } from "../../utils/utilities";
import ViewAmount from "../Bills/ViewAmount";
import InputContractAmount from "../Bills/InputContractAmount";
import ColumnBarRevenues from "../Dashboard/ColumnBarRevenues";

function MyAccountantTasks() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState();
  const [year, setYear] = useState();
  const [receivables, setReceivables] = useState([]);
  const [names, setNames] = useState([]);
  const [revenues, setRevenues] = useState([]);
  const [receivings, setReceivings] = useState([]);
  const [remainings, setRemainings] = useState([]);
  const [basicActive, setBasicActive] = useState('tab1');
  const [planAmount, setPlanAmount] = useState([]);
  const [open, setOpen] = useState(false);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "My tasks";
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserId(toNumber(infoObj.id ?? 0));
  }, []);

  useEffect(() => {
    reloadReceivables();
    reloadRevenues();
    let now = new Date();
    let curYear = now.getFullYear();
    setYear(curYear);
    setNames(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"])
  }, []);

  const reloadRevenues = () => {

    let searchYear = year ?? 0;
    if (searchYear < 1) {
      let now = new Date();
      searchYear = now.getFullYear();
    }

    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.invoiceUrl}/RevenueByYears/${searchYear}`,
        configHeader()
      )
      .then((result) => {

        let items = result?.data?.revenue ?? [];
        let itemReceivings = result?.data?.receiving ?? []
        let rsRevenues = [];
        let rsReceivings = [];
        let rsRemainings = [];

        for (let x in items) {
          rsRevenues.push(items[x] / 1000);
        }

        for (let x in itemReceivings) {
          rsReceivings.push(itemReceivings[x] / 1000);
          //rsRemainings.push((items[x] - itemReceivings[x]) / 1000)
        }

        setRevenues(rsRevenues);
        setReceivings(rsReceivings);
        //setRemainings(rsRemainings);
      });
  };

  const reloadReceivables = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.invoiceUrl}/Deferred-revenue`,
        configHeader()
      )
      .then((result) => {
        let items = (result?.data ?? [])
          .filter((item) => item?.projectName)
          .map((it) => {
            let totalAmouts = toNumber(it?.totalAmout);
            let totalAdvance = toNumber(it?.advancePayment);
            it.remaining = toNumber(totalAdvance > totalAmouts ? 0 : totalAmouts - totalAdvance);
            return it;
          });

        setReceivables(items ?? []);
      });
  };

  const colAccDefs = [
    {
      field: "companyName",
      headerName: "Client Name",
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      width: 215,
      filter: true
    },
    {
      field: "projectName",
      headerName: "Project Name",
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
      width: 215,
      filter: true
    },
    {
      field: "phaseName",
      headerName: "Phase Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 350,
      filter: true
    },
    {
      field: "planReceivingDate",
      headerName: "Receiving Date",
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (row) => {
        return dateFormatDMY(row.data?.planReceivingDate);
      },
      width: 175,
    },
    {
      field: "advancePayment",
      headerName: "Advance",
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.advancePayment} />
    },
    {
      field: "totalAmout",
      headerName: "Amount",
      pinned: 'right',
      lockPinned: true,
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.totalAmout} />
    },
    {
      field: "remaining",
      headerName: "Remaining",
      pinned: 'right',
      lockPinned: true,
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.remaining} />
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="file-invoice-dollar"
            onClick={() => handleOpenPopup(row.data)}
          />
        );
      },
    }
  ];

  const handleOpenPopup = (data) => {
    setPlanAmount(data);
    setOpen(true);
  }

  const handleSaveReceipt = (data) => {
    if (data?.phaseId < 1) {
      toast.error(
        "Your phase is empty, please select a phase for receipt."
      );
      return;
    }

    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.invoiceUrl}/Short-Receipt`, data,
        configHeader()
      )
      .then(() => {
        reloadReceivables();
        setOpen(false);
        toast.success("Your Receipt has been created.");
      });
  }

  const renderAccountant = () => {
    return <>
      {open && <InputContractAmount phaseInfo={planAmount}
        onCloseModal={() => setOpen(false)}
        onSave={(data) => handleSaveReceipt(data)} />}

      <div className="ag-theme-quartz" style={{ height: "65vh" }}>
        <AgGridReact
          rowData={orderBy(receivables, "name")}
          columnDefs={colAccDefs}
          fullWidthCellRenderer
          onGridReady={onGridReady}
          pagination
          paginationPageSize={10}
        />
      </div>
    </>
  }

  const renderSummary = () => {
    let items = (receivables ?? []).filter((it) => toNumber(it?.totalAmout ?? 0) > 0);

    //let workings = 0;
    let totalAdvance = 0;
    let totalRemainings = 0;
    let totalAmouts = 0;

    let projectIds = (items ?? []).map((it) => it.projectId);
    let ids = uniq(projectIds);
    (ids ?? []).map((id) => {
      (items ?? []).filter((it) => it.projectId === id)
        .map((it) => {
          totalAdvance += toNumber(it?.advancePayment ?? 0);
          totalAmouts += toNumber(it?.totalAmout ?? 0);
          totalRemainings += toNumber(it?.remaining ?? 0);
        });
    });

    return <>
      <MDBRow style={{ textAlign: "left", marginTop: 10 }}>
        <MDBCol sm="4">
          Projects
        </MDBCol>
        <MDBCol sm="4">
          <MDBBadge pill color="success" title={"Total projects"}>
            {ids?.length ?? 0}
          </MDBBadge>
        </MDBCol>
      </MDBRow>
      <MDBRow style={{ textAlign: "left", marginTop: 10 }}>
        <MDBCol sm="4">
          Total Amount
        </MDBCol>
        <MDBCol sm="4">
          <MDBBadge pill color="success" title={"Total Amouts"}>
            {toCommas(totalAmouts)}
          </MDBBadge>
        </MDBCol>
      </MDBRow>

      <MDBRow style={{ textAlign: "left", marginTop: 10 }}>
        <MDBCol sm="4">
          Advance Payment
        </MDBCol>
        <MDBCol sm="4">
          <MDBBadge pill light title={"Advance Payment"}>
            {toCommas(totalAdvance)}
          </MDBBadge>
        </MDBCol>
      </MDBRow>

      <MDBRow style={{ textAlign: "left", marginTop: 10 }}>
        <MDBCol sm="4">
          Remainings
        </MDBCol>
        <MDBCol sm="4">
          <MDBBadge pill color="warning" title={"Remainings"}>
            {toCommas(totalRemainings)}
          </MDBBadge>
        </MDBCol>
      </MDBRow>
    </>
  }

  const renderCharts = () => {
    return <MDBRow>
      <MDBCol sm="12">
        <ColumnBarRevenues categories={names}
          revenues={revenues}
          receivings={receivings}
          remainings={remainings}
        />
      </MDBCol>
    </MDBRow>
  }

  const renderBody = () => {
    return <>
      <MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
            Summary
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
            Details
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
            This year
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={basicActive === 'tab1'}>{renderSummary()}</MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab2'}>{renderAccountant()}</MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab3'}>{renderCharts()}</MDBTabsPane>
      </MDBTabsContent>
    </>
  }

  return (
    <>
      {renderBody()}
      <ToastContainer />
    </>
  );
}

export default MyAccountantTasks;
