import { AgGridReact } from "ag-grid-react";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { difference, first, orderBy } from "lodash";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { dateFormatDMY, onGridReady } from "../../utils/utilities";
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import Multiselect from "multiselect-react-dropdown";
import DisplayUser from "../../components/DisplayUser";
import DisplayPM from "../../components/DisplayPM";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AssignProject() {
  const navigate = useNavigate();
  const [staffs, setStaffs] = useState();
  const [projects, setProjects] = useState();
  const [allProjects, setAllProjects] = useState();
  const [useProjects, setUseProjects] = useState([]);
  const [curProjects, setCurProjects] = useState([]);
  const [userId, setUserId] = useState();
  const [assistants, setAssistants] = useState([]);
  const [minRow, setMinRow] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Assign Project Manager";
  }, []);

  useEffect(() => {
    loadDataStaffs();
    loadDataProjects();
  }, []);

  const loadDataStaffs = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/All-Users`,
        configHeader()
      )
      .then((result) => {
        setStaffs((result?.data ?? []).filter((it) => it.isActive));
      });
  };

  const loadDataProjects = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.projectUrl}`,
        configHeader()
      )
      .then((result) => {
        var items = orderBy(result?.data ?? [], "priorityNumber");
        setAllProjects(items);
        setProjects(items);
      });
  };

  const colDefs = [
    // {
    //   headerName: "Row",
    //   valueGetter: "node.rowIndex + 1",
    //   width: 65,
    // },
    {
      field: "name",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
      pinned: 'left',
      lockPinned: true,
      width: 145,
    },
    {
      field: "assigned",
      filter: true,
      headerName: "PM",
      pinned: 'left',
      lockPinned: true,
      cellRenderer: (data) => {
        if (!data?.value) return <></>;
        return <DisplayPM userName={data?.value} />;
      },
      width: 145,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "description",
      width: 375,
      autoHeight: true,
      cellStyle: {
        'white-space': minRow ? "" : 'pre',
        textAlign: "left",
      },
    },
    {
      field: "statusName",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
      width: 175,
    },
    {
      field: "assistant",
      headerName: "Assistant PM",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
      width: 135,
    },
    {
      field: "startDate",
      width: 175,
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "endDate",
      width: 175,
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "approvalBy",
      filter: true,
      width: 175,
      cellRenderer: (data) => {
        if (!data?.value) return <></>;
        return <DisplayUser userName={data?.value} />;
      },
      cellStyle: {
        textAlign: "left",
      },
    },
  ];

  const columAssignedDefs = [
    // {
    //   headerName: "Row",
    //   valueGetter: "node.rowIndex + 1",
    //   width: 65,
    // },
    {
      field: "name",
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "statusName",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
      width: 125
    },
    {
      field: "startDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="times"
            color="warning"
            onClick={() => handleRemove(row.data.id)}
          />
        );
      },
    },
    {
      field: "endDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    }
  ];

  const filterProjects = (items, others) => {
    let rs = items ?? [];
    return difference(rs, others);
  };

  const handleRemove = (id) => {
    let rs = (useProjects ?? []).filter((s) => s.id !== id);
    let items = filterProjects(allProjects, rs);
    setProjects(items);
    setUseProjects(rs);
  };

  const onSelectionChanged = (event) => {
    const selectedData = event.api.getSelectedRows();
    setCurProjects(selectedData);
  };

  const handleSelect = () => {
    let rs = (curProjects ?? []).concat(useProjects ?? []);
    let items = filterProjects(allProjects, rs);
    setProjects(items);
    setUseProjects(rs);
  };

  const options = () => {
    return (staffs ?? []).map((p) => {
      return { name: p.displayName, id: p.id };
    });
  };

  const assistantOptions = () => {
    return (staffs ?? []).map((p) => {
      return { name: p.displayName, id: p.id };
    });
  }

  const onSelectedAssistantValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (staffs ?? [])
      .filter((p) => ids.some((id) => id === p.id)) ?? [];
    setAssistants(rs);
  };

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (staffs ?? []).filter((p) => ids.some((id) => id === p.id));
    let pro = first(rs) ?? {};
    setUserId(pro?.id);
  };

  const saveData = () => {
    if ((useProjects ?? []).length < 1) {
      toast.error("Please select some project(s) for this assignment");
      return;
    }

    let idAss = (assistants ?? []).map((it) => it.id);
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/${userId}/Assign-Projects`,
        { ids: (useProjects ?? []).map((pro) => pro.id), assistantIds: idAss ?? [] },
        configHeader()
      )
      .then(() => {
        loadDataProjects();
        toast.success("Assign projects to PM was successful");
      });
  };

  const renderCtrl = () => {
    let ctrMin = minRow ? "" : <MDBIcon fas icon="minus-square"
      onClick={() => setMinRow(true)}
      title="Minimize view description"
      style={{ cursor: "pointer", marginRight: 25 }} />;

    let ctrExternal = !minRow ? "" : <MDBIcon
      fas
      icon="eye"
      title="External view description"
      onClick={() => setMinRow(false)}
      style={{ cursor: "pointer", marginRight: 25 }}
    />

    return <MDBRow style={{ marginBottom: 15 }}>
      <MDBCol sm="9" />
      <MDBCol sm="3" className="displayEnd">
        {ctrMin} {ctrExternal}
      </MDBCol>
    </MDBRow>
  }

  return (
    <>
      <MDBRow style={{ marginTop: 10 }}>
        <MDBCol size="12" sm="5">
          {renderCtrl()}
          <MDBRow>
            <MDBCol sm="12">
              <div className="ag-theme-quartz" style={{ height: "65vh" }}>
                <AgGridReact
                  rowData={projects}
                  columnDefs={colDefs}
                  rowSelection={"multiple"}
                  onSelectionChanged={onSelectionChanged}
                  onGridReady={onGridReady}
                  pagination
                  paginationPageSize={10}
                />
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow >
            <MDBCol sm="12" style={{ marginTop: 10 }}>
              Selecting multiple rows can be achieved by holding down ^ Ctrl and
              mouse clicking the rows. A range of rows can be selected by using ⇧
              Shift.
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size="12" sm="1">
          <MDBBtn
            type="submit"
            block
            className="mb-4"
            style={{ marginTop: 75 }}
            onClick={() => handleSelect()}
          >
            <MDBIcon fas icon="chevron-circle-right" />
          </MDBBtn>
        </MDBCol>

        <MDBCol size="12" sm="6">
          <MDBRow style={{ textAlign: "left" }}>
            <MDBCol size="3" sm="3">PM</MDBCol>
            <MDBCol size="3" sm="9">
              <Multiselect
                options={options()}
                singleSelect
                onSelect={(items) => onSelectedValues(items)}
                onRemove={(items) => onSelectedValues(items)}
                displayValue="name"
                placeholder="Select a Project manager"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ marginTop: 15, textAlign: "left" }}>
            <MDBCol size="3" sm="3">Assistants</MDBCol>
            <MDBCol size="3" sm="9">
              <Multiselect
                options={assistantOptions()}
                singleSelect
                onSelect={(items) => onSelectedAssistantValues(items)}
                onRemove={(items) => onSelectedAssistantValues(items)}
                displayValue="name"
                placeholder="Select some an Assistants"
              />
            </MDBCol>
          </MDBRow>
          <div
            className="ag-theme-quartz"
            style={{ marginTop: 25, height: "50vh" }}
          >
            <AgGridReact rowData={useProjects}
              columnDefs={columAssignedDefs} onGridReady={onGridReady}
              pagination
              paginationPageSize={10}
            />
          </div>
          <MDBBtn
            type="submit"
            block
            className="btn btn-outline-primary"
            style={{ marginTop: 15 }}
            onClick={() => saveData()}
          >
            <MDBIcon fas icon="save" /> Assign to PM
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <ToastContainer />
    </>
  );
}

export default AssignProject;
