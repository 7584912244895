import React, { useEffect, useState } from "react";
import PhaseInfo from "./PhaseInfo";
import ColumnSection from "./ColumnSection";
import { defaultBoxMinWidth, defaultBoxWidth, minSizeWindowPC } from "../../utils/utilities";
import { first } from "lodash";
import { MDBAccordion, MDBAccordionItem, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";

function MainBoard({
  columns,
  projectId,
  projects,
  statuses,
  currentPhaseId,
  statusOfPhase,
  userRole,
  isProject,
  teams,
  staffs,
  phaseStatusesInfo,
  staffsOfPhase,
  projectResources,
  onSelectProject,
  onSelectPhase,
  changeStatus,
  onSavePhase,
  handleAddSubtask,
  handleUpdateSubtask,
  onSaveProject,
  onSaveTask,
  handleDelete,
  onUpdateTask,
  handleDeleteTask,
  onClonePhase,
  onSelectTask,
  statusOfTask,
  taskInfoId,
  taskInfo,
  handleDeleteSubTask,
  reloadPhaseInfo,
  onShowCheckListOfPhase,
  onShowAllCheckListOfPhase,
  handleLogWork,
  reloadTaskInfo,
  onAddRevisionTask,
  dataToday,
  onSelectTodayTask,
  handleCreatePhaseClick,
  handleCreateTaskClick,
  handleCreateSubTaskClick,
  handleSavePhaseResource,
  selectedProject,
  selectedPhase,
  phaseName
}) {

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const [colWidth, setColWidth] = useState(-1);
  const [hideDone, setHideDone] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const handleDragTask = (colIndex, prevColIndex, taskIndex) => {
    let curStatus = columns[colIndex].status;
    let curTaskId = columns[prevColIndex].entities[taskIndex]?.id;
    let prevStatus = columns[prevColIndex].status;
    let items = columns[prevColIndex].entities ?? [];
    let firstItem = first(items) ?? {};
    let isPM = firstItem?.isPM;
    if (!isPM) {
      items = columns[colIndex].entities ?? [];
      firstItem = first(items) ?? {};
      isPM = firstItem.isPM;
    }
    changeStatus(isPM, curTaskId, curStatus, prevStatus);
  };

  const handleSetColWidth = (idx) => {
    setColWidth(idx)
  }

  const handleChangeHideDone = (val) => {
    setHideDone(val);
  }

  const renderColumnSections = (items) => {
    let curItems = !hideDone ? items : (items ?? []).filter((it) => it.status !== "Done");
    return (curItems ?? []).map((col, index) => (
      <ColumnSection
        isSideBarOpen={isSideBarOpen}
        key={index}
        colIndex={index}
        statusOfPhase={statusOfPhase}
        isProject={isProject}
        teams={teams}
        staffs={staffs}
        userRole={userRole}
        staffsOfPhase={staffsOfPhase}
        projectResources={projectResources}
        columnInfo={col}
        dragTask={(colIndex, prevColIndex, taskIndex) =>
          handleDragTask(colIndex, prevColIndex, taskIndex)
        }
        onSavePhase={onSavePhase}
        handleAddSubtask={handleAddSubtask}
        handleUpdateSubtask={handleUpdateSubtask}
        onSaveTask={onSaveTask}
        handleDelete={handleDelete}
        onUpdateTask={onUpdateTask}
        handleDeleteTask={handleDeleteTask}
        onClonePhase={onClonePhase}
        taskInfoId={taskInfoId}
        taskInfo={taskInfo}
        statusOfTask={statusOfTask}
        handleDeleteSubTask={handleDeleteSubTask}
        reloadPhaseInfo={reloadPhaseInfo}
        currentPhaseId={currentPhaseId}
        projectId={projectId}
        phaseStatusesInfo={phaseStatusesInfo}
        handleLogWork={handleLogWork}
        reloadTaskInfo={reloadTaskInfo}
        onAddRevisionTask={onAddRevisionTask}
        colWidth={colWidth === index ? defaultBoxWidth : defaultBoxMinWidth}
        handleViewWidth={() => handleSetColWidth(index)}
        handleMinView={() => handleSetColWidth(-1)}
        handleCreatePhaseClick={handleCreatePhaseClick}
        handleCreateTaskClick={handleCreateTaskClick}
        handleCreateSubTaskClick={handleCreateSubTaskClick}
        handleSavePhaseResource={handleSavePhaseResource}
      />
    ))
  }

  const renderProjectOnMobile = () => {    
    return <div className="bg-[#f4f7fd] h-screen flex dark:bg-[#20212c] overflow-x-scroll ga p-1"
      style={{
        height: "86vh",
        backgroundImage: "linear-gradient(white, gray)",
      }}>
      <MDBAccordion alwaysOpen initialActive={1}>
        {(projects ?? []).map((project) => {
          let titleTab = `Project: ${project?.name} 
          PM : ${project?.pm} 
          Description: ${project?.description}`;

          if ((project?.phases ?? []).length < 1) return <>TBD</>

          return <MDBAccordionItem collapseId={project?.id ?? 1}
            headerTitle={project?.name}
            color={project?.highlight ? 'warning' : "none"}
            title={titleTab}>
            {(project?.phases ?? []).map((ph) => {
              let phaseIcon = currentPhaseId === ph.id ? "" : <MDBIcon fas icon="calendar-alt" style={{ marginRight: 15 }} />
              return <MDBRow>
                <MDBCol sm="12" style={{ textAlign: "left" }} >
                  <div
                    title={`Phase : ${ph?.name}, Description ${ph?.description}`}
                    color={taskInfoId === ph?.id ? "success" : "transparent"}
                    className=" truncate max-w-[175px]"
                    onClick={() => onSelectPhase(project, ph)}
                  >
                    {phaseIcon}
                    {ph?.name}
                  </div>
                  {(ph?.taskMenus ?? []).map((ts, idx) => {
                    return <MDBRow>
                      <MDBCol sm="1" />
                      <MDBCol sm="10">
                        <div
                          id={`${idx}-${ts?.id}`}
                          title={`Task : ${ts?.name}, Description ${ts?.description}`}
                          color={taskInfoId === ts?.id ? "success" : "transparent"}
                          className=" truncate max-w-[175px]"
                          onClick={() => onSelectTask(ph, ts)}
                        >
                          <MDBIcon
                            fas
                            icon="tasks"
                            style={{ margin: 8 }}
                          /> {ts?.name}
                        </div>
                      </MDBCol>
                    </MDBRow>
                  })}
                </MDBCol>
              </MDBRow>
            })}
          </MDBAccordionItem>
        })}
      </MDBAccordion>
      {renderColumnSections(columns)}
    </div>
  }

  const renderOnPC = () => {
    return <div
      style={{
        height: "86vh",
        backgroundImage: "linear-gradient(white, gray)",
      }}
      className={
        windowSize[0] >= minSizeWindowPC && isSideBarOpen
          ? " bg-[#f4f7fd] h-screen flex dark:bg-[#20212c] overflow-x-scroll g" +
          "ap-6 ml-[261px]"
          : "bg-[#f4f7fd] h-screen flex dark:bg-[#20212c] overflow-x-scroll ga" +
          "p-1 "
      }
    >
      {windowSize[0] >= minSizeWindowPC && (
        <>
          <PhaseInfo
            setIsBoardModalOpen={setIsBoardModalOpen}
            isBoardModalOpen={isBoardModalOpen}
            isSideBarOpen={isSideBarOpen}
            setIsSideBarOpen={setIsSideBarOpen}
            userRole={userRole}
            projects={projects}
            statuses={statuses}
            projectId={projectId}
            currentPhaseId={currentPhaseId}
            taskInfoId={taskInfoId}
            onSelectProject={onSelectProject}
            onSelectPhase={onSelectPhase}
            onSaveProject={onSaveProject}
            onSelectTask={onSelectTask}
            onShowCheckListOfPhase={onShowCheckListOfPhase}
            onShowAllCheckListOfPhase={onShowAllCheckListOfPhase}
            onSelectTodayTask={onSelectTodayTask}
            dataToday={dataToday}
            taskInfo={taskInfo}
            selectedProject={selectedProject}
            selectedPhase={selectedPhase}
            phaseName={phaseName}
            onSelectHideDone={(val) => handleChangeHideDone(val)}
          />
          <div className={isSideBarOpen ? `scrollbar-hide mx-8` : `scrollbar-hide mx-2`} />
        </>
      )}

      {renderColumnSections(columns)}
    </div>
  }

  return windowSize[0] >= minSizeWindowPC ? renderOnPC() : renderProjectOnMobile();
}

export default MainBoard;
