import { useEffect, useState } from "react";
import { MDBAccordion, MDBAccordionItem, MDBBadge, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import CurrentLogTimeStatus from "./CurrentLogTimeStatus";
import CreateNewTask from "../../modals/CreateNewTask";
import ConfirmDeleteDialog from "../../components/ConfirmDialog";
import UpdatePhase from "../../modals/UpdatePhase";
import ClonePhase from "../../modals/ClonePhase";
import "./Project.css";
import { apiEndpoint } from "../../utils/apiEndpoint";
import axios from "axios";
import { configHeader } from "../../utils/tokenHelper";
import DisplayTaskProcessing from "../Planning/DisplayTaskProcessing";
import ViewBoardItem from "../TaskInfo/ViewBoardItem";
import { defaultBoxMinWidth, defaultBoxWidth, userRoles } from "../../utils/utilities";
import CtrlDisplayExtentDetail from "../../components/CtrlDisplayExtentDetail";
import DisplayOverdue from "../../components/DisplayOverdue";
import DisplayMultiRows from "../../components/DisplayMultiRows";
import PhaseAddResource from "../../modals/PhaseAddResource";
import DisplayUserAndName from "../../components/DisplayUserAndName";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhasePublish from "../../modals/PhasePublish";

function CellPhaseSection({
  colIndex,
  taskIndex,
  cellInfo,
  userRole,
  statusOfPhase,
  teams,
  staffs,
  statuses,
  projectResources,
  onSave,
  onSaveTask,
  handleDelete,
  onClonePhase,
  colWidth,
  handleViewWidth,
  handleSavePhaseResource
}) {
  const phaseInfo = cellInfo ?? {};
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCloneOpen, setIsCloneOpen] = useState(false);
  const [isRemoveResourceOpen, setIsRemoveResourceOpen] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isResourceOpen, setIsResourceOpen] = useState(false);
  const [isPublishPhase, setIsPublishPhase] = useState(false);

  const [phaseId, setPhaseId] = useState(phaseInfo?.id);
  const [resourceId, setResourceId] = useState();
  //const [userId, setUserId] = useState();
  //const [statuses, setStatuses] = useState(statusOfPhase ?? []);
  const [modalWidth, setModalWidth] = useState(colWidth);

  useEffect(() => {
    setModalWidth(colWidth);
  }, [colWidth]);

  // useEffect(() => {
  //   var info = window.localStorage.getItem("userInfo") ?? "";
  //   let infoObj = JSON.parse(info);
  //   setUserId(toNumber(infoObj?.id ?? 0));
  // }, []);

  const handleRemoveResource = (phaseId, resourceId) => {
    axios
      .put(
        `${apiEndpoint.hosting}/Phases/${phaseId}/Resources/${resourceId}/Remove`,
        {},
        configHeader()
      )
      .then(() => {
        toast.success("The resource has been removed, please reload again.")
      })
      .catch(() => {
        toast.error(
          "Something went wrong, please try again later."
        );
      });
  }

  const handleOnDrag = (e) => {
    e.dataTransfer.setData(
      "text",
      JSON.stringify({ taskIndex, prevColIndex: colIndex })
    );
  };

  const renderCtrlAddDelete = () => {
    return <MDBRow>
      <MDBCol size="3" sm="4" title={"Add new task"}>
        <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => setOpenTaskModal(true)}
        >
          <MDBIcon fas icon="plus" />
        </div>
      </MDBCol>
      <MDBCol size="3" sm="4" title={"Publish this phase"}>
        <MDBIcon fas icon="file-export" onClick={() => {
          setIsPublishPhase(true);
          setPhaseId(phaseInfo?.id);
        }} />
      </MDBCol>
      <MDBCol size="3" sm="4" title="Delete this phase">
        <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => {
            setIsConfirmDelete(true);
            setPhaseId(phaseInfo?.id);
          }}
        >
          <MDBIcon fas icon="trash-alt" color="warning" />
        </div>
      </MDBCol>
    </MDBRow>
  }

  const renderAddTask = () => {
    let colName = "9";
    let colCtrName = "3";
    if ((modalWidth ?? defaultBoxMinWidth) <= defaultBoxMinWidth) {
      colName = "8";
      colCtrName = "4";
    }

    if (userRole !== userRoles.Admin && !phaseInfo?.isPM) {
      return (
        <MDBRow style={{ marginTop: 5 }}>
          <MDBCol
            size="3"
            sm={colName}
            className=" truncate max-w-[250px]"
            title={`${phaseInfo?.assigned ?? "TBD"}`}
          >
            <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
            {phaseInfo?.assigned ?? "TBD"}
          </MDBCol>
        </MDBRow>
      );
    }

    return (
      <MDBRow style={{ marginTop: 5 }}>
        <MDBCol
          size="3"
          sm={colName}
          className=" truncate max-w-[250px]"
          title={`${phaseInfo?.assigned ?? "TBD"}`}
        >
          <MDBIcon fas icon={"user-alt"} style={{ marginRight: 10 }} />
          {phaseInfo?.assigned ?? "TBD"}
        </MDBCol>
        <MDBCol size="3" sm={colCtrName}>
          {renderCtrlAddDelete()}
        </MDBCol>
      </MDBRow>
    );
  };

  const renderCloneCtrl = () => {
    if (userRole !== userRoles.Admin && !phaseInfo?.isPM)
      return (
        <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => setIsModalOpen(true)}
          title={`Update information of ${phaseInfo?.name}`}
        >
          <MDBIcon fas icon="eye" />
        </div>
      );

    return <MDBRow>
      <MDBCol sm="6">
        <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => setIsModalOpen(true)}
          title={`Update information of ${phaseInfo?.name}`}
        >
          <MDBIcon fas icon="user-edit" />
        </div>
      </MDBCol>
      <MDBCol sm="6">
        <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => setIsCloneOpen(true)}
          title={`Clone ${phaseInfo?.name}`}
        >
          <MDBIcon fas icon="clone" />
        </div>
      </MDBCol>
    </MDBRow>
  };

  const [initialItem, setInitialItem] = useState(0);

  const handleMinTaskDetail = () => {
    setModalWidth(defaultBoxMinWidth);
  }

  const handleViewTaskDetail = () => {
    setInitialItem(1);
    handleViewWidth();
    setModalWidth(defaultBoxWidth);
  }

  const renderTitlePhaseDisplayInfo = () => {
    if ((modalWidth ?? defaultBoxMinWidth) <= defaultBoxMinWidth) {
      return <MDBRow>
        <MDBCol sm="6" className={` truncate max-w-[${modalWidth ?? 175}px] `} title={phaseInfo?.description}>
          <div>
            <DisplayTaskProcessing
              statusName={phaseInfo?.statusName}
              planHours={phaseInfo?.estimateHours ?? 0}
              workingHours={phaseInfo?.normalHours ?? 0}
              otHours={phaseInfo?.overTimeHours ?? 0}
              displayOverHours
            />
          </div>
        </MDBCol>
        <MDBCol sm="4">
          {renderCloneCtrl()}
        </MDBCol>
        <MDBCol sm="2">
          <CtrlDisplayExtentDetail
            tabWidth={modalWidth}
            handleViewTaskDetail={() => handleViewTaskDetail()}
            handleMinTaskDetail={() => handleMinTaskDetail()}
          />
        </MDBCol>
      </MDBRow>
    }

    return <MDBRow>
      <MDBCol sm="9" className={` truncate max-w-[${modalWidth ?? 175}px] `} title={phaseInfo?.description}>
        <div>
          <DisplayTaskProcessing
            statusName={phaseInfo?.statusName}
            planHours={phaseInfo?.estimateHours ?? 0}
            workingHours={phaseInfo?.normalHours ?? 0}
            otHours={phaseInfo?.overTimeHours ?? 0}
            displayOverHours
          />
        </div>
      </MDBCol>
      <MDBCol sm="2">
        {renderCloneCtrl()}
      </MDBCol>
      <MDBCol sm="1">
        <CtrlDisplayExtentDetail
          tabWidth={modalWidth}
          handleViewTaskDetail={() => handleViewTaskDetail()}
          handleMinTaskDetail={() => handleMinTaskDetail()}
        />
      </MDBCol>
    </MDBRow>
  }


  const renderCurrentStatus = () => {
    if (modalWidth !== defaultBoxWidth) return <></>

    return <>
      <div style={{ marginRight: 25 }}></div>
      <CurrentLogTimeStatus
        statusName={phaseInfo?.statusName}
        estimateHours={phaseInfo?.estimateHours}
        normalHours={phaseInfo?.normalHours ?? 0}
        overTime={phaseInfo?.overTimeHours ?? 0}
        startDate={phaseInfo?.startDate}
        endDate={phaseInfo?.endDate}
        hideStatus
        hideDate
      />
    </>
  }

  const renderCurrentDescription = () => {
    if (modalWidth !== defaultBoxWidth) return <></>
    return <DisplayMultiRows description={phaseInfo?.description ?? ""} />
  }

  const renderCompletedTasks = () => {
    if (phaseInfo?.totalTasks < 1) return <></>

    let title = ` ${phaseInfo?.totalDoneTasks}/${phaseInfo?.totalTasks}`;
    return <MDBBadge pill light title={"Tasks has been completed"}>
      {title}
    </MDBBadge>
  }

  const renderCtrlAddResource = () => {
    return <MDBRow style={{ marginTop: 5 }} >
      <MDBCol sm="10"></MDBCol>
      <MDBCol sm="2">
        <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => setIsResourceOpen(true)}
          title={`Add resources of ${phaseInfo?.name}`}
        >
          <MDBIcon fas icon="user-plus" />
        </div>
      </MDBCol>
    </MDBRow>
    return
  }

  const renderControlPhaseAccordion = () => {
    let initId = (modalWidth ?? defaultBoxMinWidth) <= defaultBoxMinWidth ? 0 : initialItem;
    let items = (phaseInfo?.staffs ?? []).length > 0 ? phaseInfo?.staffs : projectResources?.staffs ?? [];
    let canAddResources = userRole === userRoles.Teamleader || userRoles.Admin || phaseInfo?.isPM;

    return <MDBAccordion alwaysOpen initialActive={initId}>
      <MDBAccordionItem collapseId={1} headerTitle={<>
        <DisplayOverdue
          statusName={phaseInfo?.statusName ?? ""}
          endDate={phaseInfo?.endDate}
        />
        {renderCompletedTasks()}
        <div style={{ marginLeft: 20 }} className={` truncate max-w-[${modalWidth ?? 275}px] `}>{phaseInfo?.name}</div>
        {renderCurrentStatus()}
      </>}
        className={` truncate max-w-[${modalWidth ?? 275}px] `} title={phaseInfo?.name}>
        <p className=" font-bold tracking-wide " title={phaseInfo?.description}>
          {renderTitlePhaseDisplayInfo()}
        </p>

        <MDBAccordion borderless alwaysOpen initialActive={10}>
          <MDBAccordionItem collapseId={10} headerTitle="Phase info">
            {modalWidth === defaultBoxMinWidth && <CurrentLogTimeStatus
              statusName={phaseInfo?.statusName}
              estimateHours={phaseInfo?.estimateHours}
              normalHours={phaseInfo?.normalHours ?? 0}
              overTime={phaseInfo?.overTimeHours ?? 0}
              startDate={phaseInfo?.startDate}
              endDate={phaseInfo?.endDate}
              description={phaseInfo?.description}
              hideStatus
            />}
            {renderCurrentDescription()}
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={30} headerTitle={`Members (${items.length})`}>
            <MDBRow>
              {renderStaffOfPhase(items ?? [])}
            </MDBRow>
            {canAddResources && renderCtrlAddResource()}
          </MDBAccordionItem>
          {renderAddTask()}
        </MDBAccordion>
      </MDBAccordionItem>
    </MDBAccordion >
  }

  const renderStaffOfPhase = (staffs) => {
    let hasPer = userRole === userRoles.Admin || phaseInfo?.isPM;

    return (staffs ?? []).map((it) => {
      if (hasPer) return renderStaffHasDelete(it);
      return <MDBRow style={{ marginTop: 5 }} >
        <MDBCol size="8" className=" truncate max-w-[300px]" title={it?.displayName}>
          <DisplayUserAndName userName={it?.userName} staffName={it?.displayName} />
        </MDBCol>
        <MDBCol size="4" className=" truncate max-w-[300px]" title={it?.team}>
          {it?.team}
        </MDBCol>
      </MDBRow>
    })
  }

  const renderStaffHasDelete = (it) => {
    return <MDBRow style={{ marginTop: 5 }} >
      <MDBCol sm="2">
        <div
          style={{ cursor: "pointer", alignItems: "flex-end" }}
          onClick={() => {
            setResourceId(it?.id)
            setIsRemoveResourceOpen(true);
          }}
          title={`Remove resource ${it?.displayName}`}
        >
          <MDBIcon fas icon="user-alt-slash" />
        </div>
      </MDBCol>
      <MDBCol size="6" className=" truncate max-w-[300px]" title={it?.displayName}>
        <DisplayUserAndName userName={it?.userName} staffName={it?.displayName} />
      </MDBCol>
      <MDBCol size="4" className=" truncate max-w-[300px]" title={it?.team}>
        {it?.team}
      </MDBCol>
    </MDBRow>
  }

  return (
    <div>
      <ViewBoardItem
        ctrlAccordion={renderControlPhaseAccordion()}
        id={`phase-${colIndex}-${phaseInfo?.id}`}
        handleOnDrag={handleOnDrag}
        colWidth={colWidth}
      />

      {isRemoveResourceOpen && resourceId && <ConfirmDeleteDialog
        openModal={true}
        titleDelete={`Remove resource of ${phaseInfo?.name}`}
        onCloseModal={() => setIsRemoveResourceOpen(false)}
        onDelete={() => {
          handleRemoveResource(phaseId, resourceId);
          setIsRemoveResourceOpen(false);
        }}
      />}

      {isConfirmDelete && phaseId && (
        <ConfirmDeleteDialog
          openModal={true}
          titleDelete={`Delete phase ${phaseInfo?.name}`}
          onCloseModal={() => setIsConfirmDelete(false)}
          onDelete={() => {
            handleDelete(phaseId);
            setIsConfirmDelete(false);
          }}
        />
      )}
      {isModalOpen && (
        <UpdatePhase
          isModalOpen={isModalOpen}
          userRole={userRole}
          phaseInfo={phaseInfo}
          onSave={onSave}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {isCloneOpen && (
        <ClonePhase
          isCloneOpen={isCloneOpen}
          setIsCloneOpen={setIsCloneOpen}
          phaseInfo={phaseInfo}
          statuses={statuses ?? []}
          teams={teams ?? []}
          userRole={userRole}
          onSave={onClonePhase}
        />
      )}
      {openTaskModal && (
        <CreateNewTask
          phaseId={phaseInfo?.id}
          phaseName={phaseInfo?.name}
          statuses={statuses ?? []}
          teams={(teams ?? []).filter((item) => item.isActive)}
          userRole={userRole}
          isPM={phaseInfo?.isPM}
          onCloseModal={() => setOpenTaskModal(false)}
          onSave={(data) => onSaveTask(data)}
        />
      )}

      {isPublishPhase && <PhasePublish
        phaseInfo={phaseInfo}
        userRole={userRole}
        onClose={() => setIsPublishPhase(false)} />}
      {isResourceOpen && <PhaseAddResource
        phaseInfo={phaseInfo ?? {}}
        staffs={staffs ?? []}
        resources={(phaseInfo?.staffs ?? []).length > 0 ? phaseInfo?.staffs : projectResources?.staffs ?? []}
        onSave={(data) => {
          setIsResourceOpen(false);
          handleSavePhaseResource(phaseInfo?.id, data);
        }}
        onClose={() => setIsResourceOpen(false)} />}
      <ToastContainer />
    </div>
  );
}

export default CellPhaseSection;
