import { MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import PhaseMenuItem from "./PhaseMenuItem";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import "./Project.css";

function ProjectInfo({
  project,
  projectId,
  currentPhaseId,
  taskInfoId,
  userRole,
  onSelectPhase,
  onSelectTask,
  onSelectProject,
  onShowCheckListOfPhase,
  onShowAllCheckListOfPhase
}) {
  const [showProject, setShowProject] = useState(false);
  const toggleProject = () => setShowProject(!showProject);
  const [phases, setPhases] = useState(project?.phases ?? []);

  useEffect(() => {
    let items = (project?.phases ?? []).filter((it) => it.highlight || it.isMonitoring) ?? [];
    setPhases(items)
  }, [project])

  const renderItem = () => {
    if (phases?.length < 1) return <></>;
    return (
      <MDBBtn
        onClick={toggleProject}
        color="tertiary"
        style={{ marginLeft: 10 }}
      >
        {!showProject && <MDBIcon fas icon="caret-down" />}
        {showProject && <MDBIcon fas icon="caret-up" />}
      </MDBBtn>
    );
  };

  const renderPhaseMenus = () => {
    return (
      <MDBRow style={{ padding: 5, marginTop: 10 }} >
        {orderBy(phases ?? [], "priorityNumber").map((phase) => (
          <PhaseMenuItem
            phase={phase}
            currentId={currentPhaseId}
            taskInfoId={taskInfoId}
            onSelectPhase={onSelectPhase}            
            onSelectTask={(ph, item) => onSelectTask(ph, item)}
            userRole={userRole}
            onShowCheckListOfPhase={onShowCheckListOfPhase}
            onShowAllCheckListOfPhase={onShowAllCheckListOfPhase}
          />
        ))}
      </MDBRow>
    );
  };

  const renderMainBody = () => {
    let titleTab = `Project: ${project?.name} 
    PM : ${project?.pm} 
    Description: ${project?.description}`
    let displayStatus = (project?.statusName ?? "").length > 0 ? ` (${project?.statusName})` : ""
    let btnTitle = <MDBBtn
      style={{ cursor: "pointer", borderRadius: 16 }}
      color="info"
      title={titleTab}
      onClick={() => {
        setShowProject(true);
        onSelectProject(project);
      }}
    >
      <label
        className=" truncate max-w-[125px]"
        style={{
          textDecoration: project?.highlight ? "underline" : "",
          fontWeight: project?.highlight ? "bold" : "normal"
        }}
      >
        {project?.name} {displayStatus}
      </label>
    </MDBBtn>

    let btnTertiary = <MDBBtn
      style={{ cursor: "pointer", marginLeft: 16 }}
      title={titleTab}
      color={project?.highlight ? 'warning' : "none"}
      onClick={() => {
        setShowProject(true);
        onSelectProject(project);
      }}
    >
      <label
        className=" truncate max-w-[175px]"
        style={{
          fontWeight: project?.highlight ? "bold" : "normal",
        }}
      >
        <MDBIcon fab icon="product-hunt" style={{ marginRight: 10 }} />
        {project?.name} {displayStatus}
      </label>
    </MDBBtn>

    return (
      <>
        <MDBRow style={{ padding: 12 }} title={`PM : ${project?.pm}`}>
          <MDBCol size="3" sm="9">
            {projectId === project?.id ? btnTitle : btnTertiary}
          </MDBCol>
          <MDBCol size="3" sm="3">
            {renderItem()}
          </MDBCol>
          {showProject && renderPhaseMenus()}
        </MDBRow>
      </>

    );
  };

  return renderMainBody();
}

export default ProjectInfo;
