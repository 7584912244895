import { useEffect, useState } from "react";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { apiEndpoint } from "../../utils/apiEndpoint";
import ApexPie from "./ApexPie";
import Multiselect from "multiselect-react-dropdown";
import { Card } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import ProjectByDates from "./ProjectByDates";
import PhaseByDates from "./PhaseByDates";
import ColumnBarChart from "./ColumnBarChart";
import { AgGridReact } from "ag-grid-react";
import { dateFormatDMY, userRoles } from "../../utils/utilities";
import {
  MDBAccordion,
  MDBAccordionItem,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
//import { sumBy, uniqBy } from "lodash";
import DisplayUser from "../../components/DisplayUser";
import TimeSheetsBasic from "./TimeSheetsBasic";
import TaskByUsers from "./TaskByUsers";
import MyTasks from "../Planning/MyTasks";

const Dashboard = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState();
  const [timeSheets, setTimeSheets] = useState();
  const [selectedProjects, setSelectedProjects] = useState();
  const [rangeDates, setRangeDates] = useState();
  const [summary, setSummary] = useState([]);
  const [canModify, setCanModify] = useState(false);
  const [reportByTime, setReportByTime] = useState();
  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Dashboard";

    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    let userRole = infoObj.role ?? "";
    if (userRole === userRoles.Admin || userRole === "Manager") {
      setCanModify(true);
    }
  }, []);

  useEffect(() => {
    loadPieChart();
    loadTimesheets();
    loadSummary();
    loadReportByTime();
  }, []);

  const loadReportByTime = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/ReportByTime`,
        configHeader()
      )
      .then((result) => {
        setReportByTime(result?.data);
      });
  };

  const loadSummary = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/Summary-Reports`,
        configHeader()
      )
      .then((result) => {
        setSummary(result?.data);
      });
  };

  const loadTimesheets = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/Timesheets`,
        configHeader()
      )
      .then((result) => {
        setTimeSheets(result?.data);
      });
  };

  const loadPieChart = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.dashboardUrl}/Pie-Chart`,
        configHeader()
      )
      .then((result) => {
        setProjects(result?.data);
      });
  };

  const options = () => {
    return (projects ?? [])
      .filter((p) => p.id > 0 && p.name?.length > 0)
      .map((p) => {
        return { name: p.name, id: p.id };
      });
  };

  const colAdminDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "projectName",
      width: 145,
      filter: true,
      headerName: "Project",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "phaseName",
      filter: true,
      headerName: "Phase name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "planningHours",
      width: 85,
      headerName: "Planning",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "assigned",
      width: 125,
      headerName: "Responsible",
      filter: true,
      cellRenderer: (data) => {
        return <DisplayUser userName={data?.value} />;
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "teamName",
      headerName: "Team",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "taskName",
      filter: true,
      headerName: "Task Name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "statusName",
      headerName: "Status",
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "estimateHours",
      width: 65,
      headerName: "Estimate",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "normalHours",
      width: 65,
      headerName: "Working",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "overTimeHours",
      width: 65,
      headerName: "OT",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "fromDate",
      headerName: "From Date",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "toDate",
      headerName: "To Date",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
  ];

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "taskName",
      headerName: "Task Name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "statusName",
      headerName: "Status",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "estimateHours",
      width: 65,
      headerName: "Estimate",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "normalHours",
      width: 65,
      headerName: "Working",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "overTimeHours",
      width: 65,
      headerName: "OT",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "fromDate",
      headerName: "From Date",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "toDate",
      headerName: "To Date",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "assigned",
      width: 125,
      filter: true,
      headerName: "Staff",
      cellRenderer: (data) => {
        return <DisplayUser userName={data?.value} />;
      },
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "phaseName",
      headerName: "Phase name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "projectName",
      filter: true,
      width: 95,
      headerName: "Project",
      cellStyle: {
        textAlign: "left",
      },
    },
  ];

  const onSelectedValues = (items) => {
    let ids = (items ?? []).map((it) => it.id);
    let rs = (projects ?? []).filter((p) => ids.some((id) => id === p.id));
    let dates = [];

    (rs ?? []).map((it) => it.rangeDates.map((date) => dates.push(date)));
    setSelectedProjects(rs);
    setRangeDates(dates);
  };
  const renderViewProjects = () => {
    return <MDBAccordion alwaysOpen initialActive={1}>
      <MDBAccordionItem collapseId={1} headerTitle={"By Projects"}>
        <ProjectByDates projects={selectedProjects} />
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={2} headerTitle={"By Phases"}>
        <PhaseByDates rangeDates={rangeDates} />
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={3} headerTitle={"Pie Charts"}>
        <div className="row">
          {(selectedProjects ?? []).map((project) => {
            return (
              <div className="col-6">
                <h3>{project.name}</h3>
                <ApexPie labels={project.names} series={project.hours} />
              </div>
            );
          })}
        </div>
      </MDBAccordionItem>
    </MDBAccordion>
  }

  const renderCharts = () => {
    return (
      <>
        Select project(s) for view
        <Card style={{ marginTop: 20 }}>
          <Multiselect
            options={options()}
            onSelect={(items) => onSelectedValues(items)}
            onRemove={(items) => onSelectedValues(items)}
            displayValue="name"
            hidePlaceholder={(selectedProjects ?? []).length > 0}
            placeholder="Select project(s) for view"
          />
        </Card>

        {(selectedProjects ?? []).length > 0 && renderViewProjects()}

      </>
    );
  };

  const renderTimeSheets = () => {
    return (
      <MDBRow>
        <MDBCol size="3" sm="6">
          <TimeSheetsBasic timeSheets={timeSheets} />
        </MDBCol>
        <MDBCol size="3" sm="6">
          <ColumnBarChart timeSheets={timeSheets} />
        </MDBCol>
      </MDBRow>
    );
  };

  return (
    <>
      <MDBAccordion alwaysOpen initialActive={5}>
        <MDBAccordionItem collapseId={5} headerTitle={"My tasks"}>
          <MyTasks />
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={1} headerTitle={"Plannings"}>
          <TaskByUsers data={reportByTime} />
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle={"Time Sheets"}>
          {renderTimeSheets()}
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={3} headerTitle={"List of tasks"}>
          <div
            className="ag-theme-quartz"
            style={{ height: "65vh", marginTop: 20 }}
          >
            <AgGridReact
              rowData={(summary ?? []).filter(
                (it) => canModify || (it.assigned ?? "").length > 0
              )}
              columnDefs={canModify ? colAdminDefs : colDefs}
              pagination
              paginationPageSize={10}
            />
          </div>
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={4} headerTitle={"Charts"}>
          {renderCharts()}
        </MDBAccordionItem>
      </MDBAccordion>
    </>
  );
};

export default Dashboard;
