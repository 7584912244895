import { AgGridReact } from "ag-grid-react";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { orderBy } from "lodash";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { dateFormatDMY, onGridReady } from "../../utils/utilities";
import DatePicker from "react-datepicker";
import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import moment from "moment";
import ConfirmDeleteDialog from "../../components/ConfirmDialog";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewConfirmTimesheet from "../../modals/ViewConfirmTimesheet";

function ViewMySubTask() {
  const navigate = useNavigate();
  const [projectTasks, setProjectTasks] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [subTaskName, setSubTaskName] = useState('');
  const [logtimeId, setLogtimeId] = useState();
  const [isDelete, setIsDelete] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [todayTasks, setTodayTasks] = useState([]);
  //const [todayTaskDone, setTodayTaskDone] = useState([]);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "Time Sheets";
  }, []);

  useEffect(() => {
    let date = moment(startDate).format("DD-MMM-YYYY");
    loadMyTimesheets(date);
    loadDataToday(date);
  }, []);

  const loadDataToday = (date) => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/CurrentSubtasks/${date}`,
        configHeader()
      )
      .then((result) => {
        let data = (result?.data ?? []).filter((it) => +it.taskId > 0 && it.taskStatus !== "Done") ?? [];
        setTodayTasks(data);
        // let items = (result?.data ?? []).filter((it) => +it.taskId > 0 && it.taskStatus === "Done") ?? [];
        // setTodayTaskDone(items)
      });
  };

  const loadMyTimesheets = (date) => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.userUrl}/My-Subtasks/${date}`,
        configHeader()
      )
      .then((result) => {
        setProjectTasks(result?.data);
      });
  }

  const colDefs = [
    // {
    //   headerName: "Row",
    //   valueGetter: "node.rowIndex + 1",
    //   width: 65,
    // },
    {
      field: "projectName",
      headerName: "Project",
      width: 165,
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "phaseName",
      headerName: "Phase",
      width: 165,
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "taskName",
      headerName: "Task Name",
      width: 205,
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "subTaskName",
      headerName: "SubTask",
      width: 265,
      pinned: 'left',
      lockPinned: true,
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "normalTime",
      width: 75,
      headerName: "Working Time",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "overTime",
      width: 75,
      headerName: "OT",
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "actionDate",
      width: 125,
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "deleteMyTask",
      headerName: "",
      cellStyle: {
        textAlign: "left",
      },
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="trash-alt" color="warning"
            title={`Delete log working time of subtask ${row.data.subTaskName}`}
            onClick={() => onDeleteLogtime(row.data)}
          />
        );
      },
    },
    {
      field: "startDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "endDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "tsNote",
      headerName: "Time sheet note",
      width: 365,
      filter: true,
      autoHeight: true,
      cellStyle: {
        'white-space': 'pre',
        textAlign: "left",
      },
    }
  ];

  const colMyDefs = (editable) => [
    // {
    //   headerName: "Row",
    //   valueGetter: "node.rowIndex + 1",
    //   width: 65,
    // },
    {
      field: "projectName",
      headerName: "Project Name",
      width: 165,
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "phaseName",
      headerName: "Phase Name",
      width: 165,
      filter: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "taskName",
      headerName: "Task Name",
      width: 165,
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "taskStatus",
      width: 125,
      filter: true,
      pinned: 'left',
      headerName: "Status",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "subTaskName",
      headerName: "SubTask",
      width: 215,
      filter: true,
      lockPinned: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "statusName",
      width: 125,
      headerName: "Sub Status",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "normalTime",
      width: 95,
      headerName: "Working",
      editable: editable,
      cellRenderer: (data) => {
        return +data?.value > 0 ? +data?.value : "";
      },
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "overTime",
      width: 95,
      headerName: "OT",
      cellRenderer: (data) => {
        return +data?.value > 0 ? +data?.value : "";
      },
      editable: editable,
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "startDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "endDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
    },
    {
      field: "tsNote",
      headerName: "Time sheet note",
      width: 365,
      filter: true,
      editable: editable,
      autoHeight: true,
      cellStyle: {
        'white-space': 'pre',
        textAlign: "left",
      },
    },
    {
      field: "view",
      headerName: "",
      cellStyle: {
        textAlign: "left",
      },
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        return (
          <MDBCol sm="2">
            <MDBIcon fas icon="list-alt"
              style={{ cursor: "pointer" }}
              title={`View task ${row?.data?.taskName}`}
              onClick={() => handleViewTaskDetail(row?.data?.taskId, row?.data?.phaseId)}
            />
          </MDBCol>
        );
      },
    },
  ];

  const handleViewTaskDetail = (taskId, phaseId) => {
    if (+taskId < 1) return;

    let viewTaskDetail = {
      taskId: taskId,
      phaseId: phaseId
    };
    window.localStorage.setItem("viewTaskDetail", JSON.stringify(viewTaskDetail));
    navigate(`/tasks/${taskId ?? 0}`);
  }

  const onDeleteLogtime = (data) => {
    setLogtimeId(data?.id);
    setSubTaskName(data?.subTaskName);
    setIsDelete(true);
  }

  const handleDelete = () => {
    logtimeId && axios
      .delete(
        `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/LogWork/${logtimeId}`,
        configHeader()
      )
      .then(() => {
        setIsDelete(false);
        let date = moment(startDate).format("DD-MMM-YYYY");
        loadMyTimesheets(date);
      });
  }

  const searchData = () => {
    let date = moment(startDate).format("DD-MMM-YYYY");
    loadMyTimesheets(date);
    loadDataToday(date);
  }

  const renderControlTimesheets = () => {
    return <>
      <MDBRow>
        <MDBCol size="3" sm="3">
          <DatePicker
            selected={startDate}
            className="css-from-date-project"
            onChange={(date) => setStartDate(date)}
          />
        </MDBCol>
        <MDBCol size="3" sm="2">
          <MDBBtn
            type="submit"
            block
            style={{ width: 165 }}
            onClick={() => searchData()}
          >
            <MDBIcon fas icon="search" /> Search
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </>
  }

  const handleSaveData = () => {
    setShowSummary(true);
  }

  const saveData = () => {
    (todayTasks ?? [])
      .filter((ts) => ts?.taskId > 0)
      .map((ts) => {
        if (ts?.subTaskId > 0 && ((+ts?.overTime ?? 0) + (+ts?.normalTime ?? 0)) > 0) {
          let data = {
            overTime: +ts?.overTime,
            subTaskId: +ts?.subTaskId,
            normalTime: +ts?.normalTime,
            actionDate: startDate,
            tsNote: ts?.tsNote ?? "",
          };
          saveLogWork(ts?.taskId, data);
        }
      });
    toast.success("Your time sheet was save successful.");
    setShowSummary(false);
    searchData();
  }

  const saveLogWork = (taskId, data) => {
    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/${taskId}/LogWork`,
        data,
        configHeader()
      )
      .then(() => {
      });
  };

  return (
    <>
      {showSummary && <ViewConfirmTimesheet
        date={startDate}
        timesheets={todayTasks}
        onCloseModal={() => setShowSummary(false)}
        onSave={() => saveData()}
      />}
      {isDelete && <ConfirmDeleteDialog
        openModal={true}
        titleDelete={`Delete log working time of subtask ${subTaskName}`}
        onCloseModal={() => setIsDelete(false)}
        onDelete={() => handleDelete()}
      />}

      {renderControlTimesheets()}
      <MDBAccordion borderless alwaysOpen initialActive={2}>
        <MDBAccordionItem collapseId={1} headerTitle="My timesheets">
          <div className="ag-theme-quartz" style={{ height: "70vh" }}>
            <AgGridReact
              rowData={orderBy(projectTasks, "priorityNumber")}
              columnDefs={colDefs}
              onGridReady={onGridReady}
              pagination
              paginationPageSize={10}
            />
          </div>
        </MDBAccordionItem>
        {/* <MDBAccordionItem collapseId={3} headerTitle="Task Done">
          <div className="ag-theme-quartz" style={{ height: "50vh" }}>
            <AgGridReact
              rowData={orderBy(todayTaskDone, "priorityNumber")}
              columnDefs={colMyDefs(false)}
              onGridReady={onGridReady}
            />
          </div>
        </MDBAccordionItem> */}
        <MDBAccordionItem collapseId={2} headerTitle="Log working">
          <div className="ag-theme-quartz" style={{ height: "50vh" }}>
            <AgGridReact
              rowData={orderBy(todayTasks, "priorityNumber")}
              columnDefs={colMyDefs(true)}
              onGridReady={onGridReady}
              pagination
              paginationPageSize={10}
            />
          </div>
          <MDBRow>
            <MDBCol size="3" sm="10">Time entries allow you input logtime into several subtasks</MDBCol>
            <MDBCol size="3" sm="2">
              <MDBBtn
                type="submit"
                block
                className="btn btn-outline-primary"
                style={{ marginTop: 15 }}
                onClick={() => handleSaveData()}
              >
                <MDBIcon fas icon="save" /> Save
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBAccordionItem>
      </MDBAccordion>
      <ToastContainer />
    </>

  );
}

export default ViewMySubTask;
