import { orderBy } from "lodash";
import {
  MDBAccordionItem,
  MDBCardBody,
  MDBCol,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
} from "mdb-react-ui-kit";
import { userRoles } from "../../utils/utilities";

function PhaseMenuItem({
  phase,
  currentId,
  taskInfoId,
  userRole,
  onSelectPhase,
  onSelectTask,
  onShowCheckListOfPhase,
  onShowAllCheckListOfPhase
}) {

  const handleChangeItemClick = (item) => {
    onSelectTask(phase, item)
  }

  const renderTaskInfo = (item, idx) => {
    let displayStatus = (item?.statusName ?? "").length > 0 ? ` (${item?.statusName})` : "";
    let taskId = `${idx}-${item?.id}`
    return <MDBListGroupItem className='px-3' style={{ margin: -8 }}>
      <div
        id={taskId}
        title={`Task : ${item?.name}, Status: ${item?.statusName ?? "To do"}, Description: ${item?.description}`}
        color={taskInfoId === item?.id ? "success" : "transparent"}
        className=" truncate max-w-[185px]"
        onClick={() => handleChangeItemClick(item)}
      >
        <MDBIcon
          fas
          icon="tasks"
        //style={{ margin: 12 }}
        /> {item?.name} {displayStatus}
      </div>
    </MDBListGroupItem>

  }

  const renderTaskMenus = (taskMenus) => {
    if (taskMenus?.length < 1) return <></>;
    let items = orderBy(taskMenus ?? [], "priorityNumber");
    return (
      <MDBListGroup light>
        {(items ?? []).map((item, idx) => renderTaskInfo(item, idx)
        )}
      </MDBListGroup>
    );
  };

  const renderControlShowCheckList = () => {
    if (userRole === userRoles.Admin || phase?.isPM) {
      return (
        <MDBIcon
          fas
          icon="chalkboard-teacher"
          onClick={() => onShowAllCheckListOfPhase(phase)}
          style={{ marginTop: -5 }}
        />
      );
    }

    return (
      <MDBIcon
        fas
        icon="list-ol"
        style={{ cursor: "pointer", marginLeft: 10, marginTop: -5 }}
        title="Show check list of team"
        onClick={() => onShowCheckListOfPhase(phase)}
      />
    );
  };

  const renderBody = () => {
    let phaseIcon = currentId === phase.id ? "" :
      <MDBIcon fas icon="calendar-alt" style={{ marginRight: 15 }} />

    let displayStatus = (phase?.statusName ?? "").length > 0 ? ` (${phase?.statusName})` : ""
    if ((phase?.taskMenus ?? []).length < 1) {
      return <>
        <div onClick={() => onSelectPhase(phase)}
          style={{ cursor: "pointer", padding: 15, width: 375 }}
          title={`Name: ${phase?.name}, Status: ${displayStatus}, Description: ${phase?.description}`}
          className=" truncate max-w-[175px]">
          <MDBRow>
            <MDBCol sm="9" onClick={() => onSelectPhase(phase)}>
              <MDBCardBody className=" truncate max-w-[175px]">
                {phaseIcon} {phase?.name} {displayStatus}
              </MDBCardBody>
            </MDBCol>
            <MDBCol sm="3">
              {currentId === phase.id && renderControlShowCheckList()}
            </MDBCol>
          </MDBRow>
        </div>
      </>
    }

    //
    return <MDBAccordionItem style={{ textAlign: "left", marginLeft: -20, width: 377 }}
      collapseId={phase?.id} headerTitle={<>
        {phaseIcon}
        <MDBRow>
          <MDBCol sm="9">
            <div className=" truncate max-w-[185px]" title={`Name: ${phase?.name}, Status: ${displayStatus}, Description: ${phase?.description}`}>
              {phase?.name} {displayStatus}
            </div>
          </MDBCol>
          <MDBCol sm="3">
            {/* <MDBIcon fas icon="eye"
              onClick={() => onSelectPhase(phase)}
              style={{ cursor: "pointer", borderRadius: 16, marginLeft: 10, marginRight: 10 }} /> */}
            {currentId === phase.id && renderControlShowCheckList()}
          </MDBCol>
        </MDBRow>

      </>} title={phase?.name}>
      {renderTaskMenus(phase?.taskMenus ?? [])}
    </MDBAccordionItem>
  }

  return renderBody();

}
export default PhaseMenuItem;
