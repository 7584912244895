import {
  MDBAccordion,
  MDBAccordionItem,
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBSwitch,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import AssignTaskToTeam from "./AssignTaskToTeam";
import { first, orderBy } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CtrPlanCompleted from "../pages/Projects/CtrPlanCompleted";
import { newGuid, userRoles } from "../utils/utilities";

const statusOfTask = [
  { id: 1, statusName: "To do" },
  { id: 2, statusName: "Working" },
  { id: 3, statusName: "Waiting approval" },
  { id: 4, statusName: "Done" },
  { id: 5, statusName: "Pending" },
];

function CreateNewTask({
  phaseId,
  phaseName,
  stsName,
  statuses,
  teams,
  userRole,
  isPM,
  onCloseModal,
  onSave,
}) {
  const navigate = useNavigate();
  const [taskName, setTaskName] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [priorityNumber, setPriorityNumber] = useState();
  const [description, setDescription] = useState();
  const [statusName, setStatusName] = useState(stsName ?? "To do");
  const [estimateHours, setEstimateHours] = useState();
  const [currentTeams, setCurrentTeams] = useState([]);
  const [showInMenu, setShowInMenu] = useState(false);
  const [userTeamId, setUserTeamId] = useState();
  const [curStatuses, setCurStatuses] = useState(statusOfTask);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);

    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserTeamId(infoObj.teamId);
  }, []);

  useEffect(() => {
    if (statuses?.length > 0) setCurStatuses(statuses)
  }, [statuses])

  useEffect(() => {
    if (stsName?.length > 0) setStatusName(stsName);
    else setStatusName("To do");
  }, [stsName]);

  const handleCurrent = (name, checked) => {
    if (checked) {
      setStatusName(name);
    }
  };

  const handleShowInMenu = (checked) => {
    setShowInMenu(checked);
  };

  const saveData = () => {
    if (taskName?.length < 1) {
      toast.error("Please input Task Name");
      return;
    }

    if (phaseId < 1) {
      toast.error("Please select phase again.");
    } else {
      let teamIds = currentTeams;

      if (userRole !== userRoles.Admin && !isPM) {
        teamIds = (teams ?? [])
          .filter((item) => +userTeamId === +item.id)
          .map((x) => x.id);
      }

      let data = checked ? {
        name: taskName,
        estimateHours: estimateHours,
        startDate: startDate,
        endDate: endDate,
        priorityNumber: priorityNumber,
        phaseId: phaseId,
        statusName: statusName,
        description: description,
        showInMenu: showInMenu,
        teamIds: teamIds,
      } : {
        name: taskName,
        estimateHours: estimateHours,
        startDate: startDate,
        priorityNumber: priorityNumber,
        phaseId: phaseId,
        statusName: statusName,
        description: description,
        showInMenu: showInMenu,
        teamIds: teamIds,
      }

      onSave(data);
      onCloseModal();
    }
  };

  const handleUseTeam = (teamId, checked) => {
    let current = currentTeams ?? [];
    if (checked) {
      let hasVal = (currentTeams ?? []).filter((it) => it === teamId);
      if ((hasVal ?? []).length < 1) {
        current.push(teamId);
      }
    } else {
      current = (currentTeams ?? []).filter((it) => it !== teamId);
    }

    setCurrentTeams(current ?? []);
  };

  const renderTeamByUserRole = () => {
    if (userRole !== userRoles.Admin && !isPM) {
      let current = (teams ?? []).filter((item) => +userTeamId === +item.id);

      let info = first(current);
      return (
        <MDBRow title={`My team ${info?.name}`}>
          <MDBCol size="6" sm="10" color="secondary">
            <MDBBtn color="secondary">My team {info?.name}</MDBBtn>
          </MDBCol>
        </MDBRow>
      );
    }

    return (
      <>
        {(teams ?? []).map((item) => {
          return (
            <AssignTaskToTeam
              info={item}
              canModify={true}
              isChecked={+userTeamId === +item.id}
              handleUseTeam={(teamId, checked) =>
                handleUseTeam(teamId, checked)
              }
            />
          );
        })}
      </>
    );
  };

  const renderTaskInfo = () => {
    return <>

      <MDBRow style={{ marginTop: 15 }}>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            type="number"
            label="Estimate"
            title="Estimate Hours"
            value={estimateHours}
            style={{ padding: 5 }}
            onChange={(evt) => setEstimateHours(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="3">
          <MDBInput
            className="mb-3"
            type="number"
            label="Priority"
            value={priorityNumber}
            style={{ padding: 5 }}
            onChange={(evt) => setPriorityNumber(evt.target.value)}
          />
        </MDBCol>
        <MDBCol size="3" sm="6">
          <MDBSwitch
            id={newGuid()}
            label={"Show In Menu"}
            style={{ marginTop: 5 }}
            onChange={(e) => handleShowInMenu(e.target.checked)}
          ></MDBSwitch>
        </MDBCol>
      </MDBRow>

      <CtrPlanCompleted
        startDate={startDate}
        endDate={endDate}
        onSaveStartDate={(date) => setStartDate(date)}
        onSaveEndDate={(date) => setEndDate(date)}
        onSaveChecked={(isSelected) => setChecked(isSelected)}
      />
    </>
  }

  const renderCtrlStatusNameItems = () => {
    if (stsName?.length > 0) return <></>;

    return <MDBAccordionItem collapseId={2} headerTitle={`Status is ${statusName}`}>
      {orderBy(curStatuses ?? [], "id").map((item) => {
        return (
          <MDBRow style={{ marginBottom: 10 }}>
            <MDBCol size="3" sm="8">
              {item?.statusName}
            </MDBCol>
            <MDBCol size="3" sm="4">
              <MDBSwitch
                id={newGuid()}
                label="Current"
                checked={statusName === item?.statusName}
                onChange={(e) =>
                  handleCurrent(item.statusName, e.target.checked)
                }
              />
            </MDBCol>
          </MDBRow>
        );
      })}
    </MDBAccordionItem>
  }
  const renderBody = () => {

    return (
      <>
        <MDBAccordion alwaysOpen initialActive={1}>
          <MDBInput
            className="mb-3"
            type="text"
            label="Name"
            title="Name of task"
            value={taskName ?? ""}
            onChange={(evt) => setTaskName(evt.target.value)}
          />
          <MDBAccordionItem collapseId={1} headerTitle="Task information">
            {renderTaskInfo()}
          </MDBAccordionItem>
          {renderCtrlStatusNameItems()}
          <MDBAccordionItem collapseId={3} headerTitle="Teams">
            {renderTeamByUserRole()}
          </MDBAccordionItem>
          <MDBTextArea
            className="mb-3"
            label="Description"
            id="textAreaDescription"
            rows="{4}"
            style={{ marginTop: 20 }}
            value={description ?? ""}
            onChange={(evt) => setDescription(evt.target.value)}
          />
        </MDBAccordion>
      </>
    );
  };

  return (
    <>
      <MDBModal open={true} onClose={onCloseModal} tabIndex="-1" className="projectDialog">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>New Task of {phaseName}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={onCloseModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="divScroll">{renderBody()}</div>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={onCloseModal}>
                Close
              </MDBBtn>
              <MDBBtn onClick={() => saveData()}>Create a New Task</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <ToastContainer />
    </>

  );
}

export default CreateNewTask;
