import {
  MDBBadge,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBSwitch,
  MDBTextArea,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../utils/tokenHelper";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import PlanCompletedDate from "../components/PlanCompletedDate";
import { newGuid } from "../utils/utilities";

const statusOfSubTask = [
  { id: 1, statusName: "To do" },
  { id: 2, statusName: "Working" },
  { id: 4, statusName: "Done" },
];

function UpdateSubTask({
  taskId,
  teamId,
  taskName,
  subTaskInfo,
  onCloseModal,
  onSave,
}) {

  const navigate = useNavigate();
  const [subTaskName, setSubTaskName] = useState(subTaskInfo?.name);
  const [statusName, setStatusName] = useState(subTaskInfo?.statusName ?? "To do");
  const [priorityNumber, setPriorityNumber] = useState(subTaskInfo?.priorityNumber);
  const [description, setDescription] = useState(subTaskInfo?.description ?? "");

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
  }, []);

  const handleCurrent = (name, checked) => {
    if (checked) {
      setStatusName(name);
    }
  };

  const validateDataBeforeSave = () => {
    if ((subTaskName ?? "").length < 1) {
      toast.warning("Name is required, please input this field.");
      return false;
    }

    return true;
  };

  const saveData = () => {
    let valid = validateDataBeforeSave();
    if (valid) {
      onSave({
        name: subTaskName,
        priorityNumber: priorityNumber,
        taskId: taskId,
        statusName: statusName,
        description: description,
      });
      onCloseModal();
    }
  };

  const renderStatus = (item) => {
    return (
      <MDBCol size="3" sm="4">
        <MDBRow>
          <MDBCol size="6" sm="3">
            <MDBSwitch
              id={newGuid()}
              checked={statusName === item?.statusName}
              onChange={(e) => handleCurrent(item?.statusName, e.target.checked)}
            />
          </MDBCol>
          <MDBCol size="6" sm="9" className=" truncate max-w-[150px]">
            {item?.statusName}
          </MDBCol>
        </MDBRow>
      </MDBCol>
    );
  };

  const renderPlanCompletedDate = () => {
    let fromDate = !subTaskInfo?.startDate ? "TBD" : moment(subTaskInfo?.startDate).format("DD-MMM");
    let toDate = !subTaskInfo?.endDate ? "TBD" : moment(subTaskInfo?.endDate).format("DD-MMM");

    return (
      <>
        <MDBRow
          title={`From ${fromDate} => ${toDate}`}
        >
          <MDBCol size="3" sm="2">
            <MDBIcon fas icon="calendar-alt" />
          </MDBCol>
          <MDBCol size="3" sm="5" className=" truncate max-w-[145px]">
            <p className="text-primary">{fromDate}</p>
          </MDBCol>
          <MDBCol size="3" sm="5" className=" truncate max-w-[145px]">
            <p className="text-warning">{toDate}</p>
          </MDBCol>
        </MDBRow>
      </>
    );
  };

  const renderBody = () => {
    return (
      <>
        <MDBInput
          className="mb-3"
          type="text"
          label="Name"
          title="Name of sub task"
          value={subTaskName ?? ""}
          onChange={(evt) => setSubTaskName(evt.target.value)}
        />

        <MDBRow
          style={{ marginTop: 15 }}
          title="Select a status for this sub task"
        >
          {(statusOfSubTask ?? []).map((item) => renderStatus(item))}
        </MDBRow>
        <MDBRow style={{ marginTop: 15 }}>
          <MDBCol size="3" sm="3">
            <MDBInput
              className="mb-3"
              type="number"
              label="Priority"
              value={priorityNumber}
              style={{ padding: 5 }}
              onChange={(evt) => setPriorityNumber(evt.target.value)}
            />
          </MDBCol>
          <MDBCol size="3" sm="6">
            <PlanCompletedDate
              startDate={subTaskInfo?.startDate}
              endDate={subTaskInfo?.endDate}
            />
          </MDBCol>
          <MDBCol size="3" sm="3">
            <MDBBadge pill light={true} title={"Estimate Hours"}>
              {subTaskInfo?.estimateHours ?? "N/A"}
            </MDBBadge>
          </MDBCol>
        </MDBRow>

        <MDBTextArea
          className="mb-3"
          label="Description"
          id="textAreaDescription"
          rows="{4}"
          style={{ marginTop: 15 }}
          value={description ?? ""}
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </>
    );
  };

  const renderCtrMainBody = () => {
    return <>
      <MDBModalBody>{renderBody()}</MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="secondary" onClick={onCloseModal}>
          Close
        </MDBBtn>
        <MDBBtn onClick={() => saveData()}>Update Subtask</MDBBtn>
      </MDBModalFooter>
    </>
  }

  return (
    <MDBModal open={true} onClose={onCloseModal} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Update Sub Task of {taskName ?? ""}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCloseModal}
            ></MDBBtn>
          </MDBModalHeader>
          {renderCtrMainBody()}
        </MDBModalContent>
      </MDBModalDialog>
      <ToastContainer />
    </MDBModal>
  );
}

export default UpdateSubTask;
