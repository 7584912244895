import { AgGridReact } from "ag-grid-react";
import { MDBAccordion, MDBAccordionItem, MDBCard, MDBCardBody, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onGridReady } from "../../utils/utilities";
import axios from "axios";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { orderBy, toNumber, uniq } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateBudget from "./CreateBudget";
import ViewBudgetInfo from "./ViewBudgetInfo";
import ViewAmount from "./ViewAmount";

function PhaseBudget() {
  const navigate = useNavigate();
  const [teams, setTeams] = useState();
  const [budgets, setBudgets] = useState();
  const [budget, setBudget] = useState();
  const [projectBudgets, setProjectBudgets] = useState();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "List of Budgets";
  }, []);

  useEffect(() => {
    reloadBudgets();
    reloadAllListOfTeams();
  }, []);

  const reloadAllListOfTeams = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.matterUrl}/All-Teams`,
        configHeader()
      )
      .then((result) => {
        let items = (result?.data ?? []).filter((item) => item?.isActive)
        setTeams(items ?? []);
      });
  };

  const reloadBudgets = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/Budgets`,
        configHeader()
      )
      .then((result) => {
        let items = (result?.data ?? []).filter((item) => item?.projectName)
        setBudgets(items ?? []);
        renderProjects(items ?? [])
      });
  };

  const renderProjects = (results) => {
    let projects = (results ?? []).map((it) => it.projectName);
    let items = uniq(projects);

    let budgets = (items ?? []).map((item) => {
      let totalAmouts = 0;
      (results ?? []).filter((it) => it.projectName === item)
        .map((it) => {
          totalAmouts += toNumber(it.budgetAmount);
        });

      return {
        projectName: item,
        budgetAmount: totalAmouts
      }
    });
    setProjectBudgets(budgets)
  }

  const colDefProjects = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "projectName",
      headerName: "Project Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 215,
      filter: true
    },
    {
      field: "budgetAmount",
      headerName: "Budget Amount",
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.budgetAmount} />
    }
  ];

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "projectName",
      headerName: "Project Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 275,
      filter: true
    },
    {
      field: "phaseName",
      headerName: "Phase Name",
      cellStyle: {
        textAlign: "left",
      },
      width: 350,
      filter: true
    },
    {
      field: "budgetAmount",
      headerName: "Budget Amount",
      cellStyle: {
        textAlign: "right",
      },
      width: 135,
      cellRenderer: (row) => <ViewAmount totalAmout={row.data?.budgetAmount} />
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 95,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="edit"
            onClick={() => handleEditBudget(row.data)}
          />
        );
      },
    },
    {
      field: "teamBudgets",
      headerName: "Budgets",
      width: 415,
      cellRenderer: (row) => <ViewBudgetInfo data={row.data} />
    }
  ];

  const handleEditBudget = (budget) => {
    setBudget(budget);
    setOpenModal(true);
  }

  const handleSaveData = (data) => {
    if (data?.phaseId < 1) {
      toast.error(
        "Your phase is empty, please select a phase."
      );
      return;
    }

    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.phaseUrl}/${data?.phaseId}/bugdet`, data,
        configHeader()
      )
      .then(() => {
        reloadBudgets();
        setOpenModal(false);
        setBudget({})
        toast.success("Your Budgets has been created.");
      });
  }

  return <>
    <MDBCard>
      <MDBCardBody>
        {openModal &&
          <CreateBudget
            budget={budget}
            teams={teams}
            onCloseModal={() => setOpenModal(false)}
            onSave={(data) => handleSaveData(data)}
          />}
        <MDBRow>
          <lable className="displayEnd">One thousand in Vietnamese (1000 VND)</lable>
        </MDBRow>
        <MDBAccordion initialActive={1}>
          <MDBAccordionItem collapseId={1} headerTitle={"View by projects"} style={{ marginTop: 25 }}>
            <div className="ag-theme-quartz" style={{ height: "65vh" }}>
              <AgGridReact
                rowData={orderBy(projectBudgets, "name")}
                columnDefs={colDefProjects}
                fullWidthCellRenderer
                onGridReady={onGridReady}
                pagination
                paginationPageSize={10}
              />
            </div>
          </MDBAccordionItem>
          <MDBAccordionItem collapseId={2} headerTitle={"View by phases"}>
            <div className="ag-theme-quartz" style={{ height: "65vh" }}>
              <AgGridReact
                rowData={orderBy(budgets, "name")}
                columnDefs={colDefs}
                fullWidthCellRenderer
                onGridReady={onGridReady}
                pagination
                paginationPageSize={10}
              />
            </div>
          </MDBAccordionItem>
        </MDBAccordion>


      </MDBCardBody>
    </MDBCard>
    <ToastContainer />
  </>;
}

export default PhaseBudget;
