import { AgGridReact } from "ag-grid-react";
import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import UpdateDisplayName from "../Planning/UpdateDisplayName";
import { apiEndpoint } from "../../utils/apiEndpoint";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../utils/utilities";

function StatusList() {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState();
  const [statuses, setStatuses] = useState();
  const [displayName, setDisplayName] = useState();
  const [statusName, setStatusName] = useState();
  const [statusId, setStatusId] = useState();
  const [openChangeNameModal, setOpenChangeNameModal] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "User settings";
  }, []);

  useEffect(() => {
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
    reloadAllListOfStatus();
  }, []);

  const reloadAllListOfStatus = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.matterUrl}/All-Status`,
        configHeader()
      )
      .then((result) => {
        setStatuses(result?.data ?? []);
      });
  };

  const colDefs = [
    {
      headerName: "Row",
      valueGetter: "node.rowIndex + 1",
      width: 65,
    },
    {
      field: "statusName",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 95,
      cellRenderer: (row) => {
        return (
          <MDBIcon
            fas
            icon="edit"
            onClick={() =>
              handleEditStatusName(row.data.id, row.data.statusName)
            }
          />
        );
      },
    },
  ];

  const handleEditStatusName = (id, statusName) => {
    setStatusId(id);
    setStatusName(statusName);
    setOpenChangeNameModal(true);
  };

  const saveData = () => {
    if ((displayName ?? "").length < 1) {
      toast.error("Status name is required.");
      return;
    }

    axios
      .post(
        `${apiEndpoint.hosting}/${apiEndpoint.matterUrl}/Status`,
        { statusName: displayName },
        configHeader()
      )
      .then(() => {
        reloadAllListOfStatus();
        toast.success(`New status ${displayName} was changed successful.`);
      });
  };

  const updateStatusName = () => {
    axios
      .put(
        `${apiEndpoint.hosting}/${apiEndpoint.matterUrl}/Status/${statusId}`,
        { id: statusId, statusName: statusName },
        configHeader()
      )
      .then(() => {
        reloadAllListOfStatus();
        setOpenChangeNameModal(false);
        toast.success("Status was changed successful.");
      });
  };

  const renderStatus = () => {
    if (userRole !== userRoles.Admin) {
      return <></>;
    }

    return (
      <>
        {openChangeNameModal && (
          <UpdateDisplayName
            setIsOpenModal={setOpenChangeNameModal}
            displayName={statusName}
            setDisplayName={setStatusName}
            updateDisplayName={updateStatusName}
          />
        )}
        <div className="ag-theme-quartz" style={{ height: "45vh" }}>
          <AgGridReact rowData={statuses}
            columnDefs={colDefs}
            pagination
            paginationPageSize={10} />
        </div>
        <MDBRow style={{ marginTop: 10 }}>
          <MDBCol size="3" sm="9">
            <MDBInput
              className="mb-3"
              type="text"
              id="displayName"
              label="Status Name"
              value={displayName}
              style={{ padding: 5 }}
              onChange={(evt) => setDisplayName(evt.target.value)}
            />
          </MDBCol>
          <MDBCol size="3" sm="3">
            <MDBBtn onClick={() => saveData()} color="info">
              Save
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </>
    );
  };

  return (
    <>
      {renderStatus()}
      <ToastContainer />
    </>
  );
}

export default StatusList;
