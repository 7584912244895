import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { configHeader, isTokenExpired } from "../../utils/tokenHelper";
import { apiEndpoint } from "../../utils/apiEndpoint";
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { orderBy } from "lodash";
import { MDBCheckbox, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { cellStyleDone, cellStyleOverdue, dateFormatDMY, nextMonth, onGridReady, userRoles } from "../../utils/utilities";
import moment from "moment";

const LeaderApproval = () => {
  const navigate = useNavigate();
  const [tasksNeedApproval, setTasksNeedApproval] = useState([]);
  const [userRole, setUserRole] = useState();
  const [myTeamId, setMyTeamId] = useState();
  const [hideDone, setHideDone] = useState(false);
  const [minRow, setMinRow] = useState(false);

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    document.title = "List tasks need to Approval";
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserRole(infoObj.role);
    setMyTeamId(+infoObj.teamId);
  }, []);

  useEffect(() => {
    reloadAllTasks();
  }, []);

  const reloadAllTasks = () => {
    axios
      .get(
        `${apiEndpoint.hosting}/${apiEndpoint.taskUrl}/Need-Approval`,
        configHeader()
      )
      .then((result) => {
        var items = (result?.data ?? [])
          .map((item) => {
            let st = item?.statusName !== "Done";
            var time1 = moment(item?.endDate ?? nextMonth()).format('YYYY-MM-DD');
            var time2 = moment(new Date()).format('YYYY-MM-DD');
            let overdue = time1 < time2;
            item.overdue = st && overdue;
            return item;
          });

        setTasksNeedApproval(orderBy(items, "priorityNumber"));
      });
  };

  const colDefs = [
    {
      field: "projectName",
      headerName: "Project Name",
      pinned: 'left',
      lockPinned: true,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      filter: true
    },
    {
      field: "phaseName",
      headerName: "Phase Name",
      filter: true,
      pinned: 'left',
      lockPinned: true,
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      width: 135,
    },
    {
      field: "name",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      pinned: 'left',
      lockPinned: true,
      filter: true
    },
    {
      field: "assigned",
      headerName: "PM",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },

      filter: true
    },
    {
      field: "teamName",
      headerName: "Team Name",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      width: 165,
      filter: true
    },
    {
      field: "approvalBy",
      headerName: "Reviewer",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      width: 165,
      filter: true
    },
    {
      field: "description",
      cellStyle: {
        'white-space': minRow ? "" : 'pre',
        textAlign: "left",
      },
      width: 375,
      filter: true,
      autoHeight: true
    },
    {
      field: "statusName",
      headerName: "Status",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        if (row.data?.overdue) {
          return cellStyleOverdue;
        }

        return {
          textAlign: "left",
        };
      },
      width: 125,
      filter: true
    },
    {
      field: "startDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "endDate",
      cellRenderer: (data) => {
        return dateFormatDMY(data?.value);
      },
      width: 125,
    },
    {
      field: "estimateHours",
      width: 95,
      filter: "agNumberColumnFilter",
      cellStyle: row => {
        if (row.data?.statusName === "Done") {
          return cellStyleDone;
        }

        return {
          textAlign: "right",
        };
      },
      cellRenderer: (data) => {
        return !data?.value ? "" : data?.value;
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinned: 'right',
      lockPinned: true,
      cellRenderer: (row) => {
        if (row.data?.approvalDate !== null) return <><MDBIcon fas icon="check-circle" title={`Reviewer ${row.data?.approvalBy}`} /></>

        if (row.data?.statusName === "Done") return <></>

        let isPM = row.data?.isPM ?? false;
        if (isPM || userRole === userRoles.Admin) return <MDBIcon fas icon="signature" title={`PM approval task ${row.data?.name}`} onClick={() => handlePMApprovalTask(row.data)} />

        return <>
          <MDBIcon fas icon="signature" title={`An approval task ${row.data?.name}`} onClick={() => handleApprovalTask(row.data)} />
        </>
      },
    },
  ]

  const handlePMApprovalTask = (data) => {
    axios
      .put(
        `${apiEndpoint.hosting}/Tasks/${data?.id}/Approval/Teams/${data?.teamId}`,
        data,
        configHeader()
      )
      .then(() => {
        reloadAllTasks();
        toast.success(`The task ${data?.name} has been approved`)
      })
      .catch(() => {
        toast.error(
          "Something went wrong, please try again later"
        );
      });
  }

  const handleApprovalTask = (data) => {

    axios
      .put(
        `${apiEndpoint.hosting}/Tasks/${data?.id}/Approval/Teams/${myTeamId}`,
        data,
        configHeader()
      )
      .then(() => {
        reloadAllTasks();
        toast.success(`The task ${data?.name} has been approved`)
      })
      .catch(() => {
        toast.error(
          "Something went wrong, please try again later"
        );
      });
  }

  const createSiteBar = () => {
    let ctrMin = minRow ? "" : <MDBIcon fas icon="minus-square"
      onClick={() => setMinRow(true)}
      title="Minimize view description"
      style={{ cursor: "pointer", marginRight: 25 }} />;

    let ctrExternal = !minRow ? "" : <MDBIcon
      fas
      icon="eye"
      title="External view description"
      onClick={() => setMinRow(false)}
      style={{ cursor: "pointer", marginRight: 25 }}
    />

    let ctrChcbox = <MDBCheckbox
      id='chboxHideDone'
      label='Hide task has been finished'
      style={{ marginRight: 15 }}
      onChange={(e) => setHideDone(e.target.checked)}
    />

    if (userRole !== userRoles.Admin) return <MDBRow>
      <MDBCol sm="1" />
      <MDBCol sm="8" style={{ textAlign: "left" }}>{ctrChcbox}</MDBCol>
      <MDBCol sm="3" className="displayEnd">
        {ctrMin} {ctrExternal}
      </MDBCol>
    </MDBRow>

    return (
      <MDBRow>
        <MDBCol sm="1" />
        <MDBCol sm="8" style={{ textAlign: "left" }}>{ctrChcbox}</MDBCol>
        <MDBCol sm="3" className="displayEnd">
          {ctrMin} {ctrExternal}
        </MDBCol>
      </MDBRow>
    );
  };

  const renderGrids = () => {
    let items = tasksNeedApproval ?? [];
    if (hideDone) {
      items = items.filter((item) => item?.statusName !== "Done");
    }

    return <AgGridReact
      rowData={orderBy(items, "priorityNumber")}
      columnDefs={colDefs}
      onGridReady={onGridReady}
      pagination
      paginationPageSize={10}
    />
  }

  return (
    <>
      {createSiteBar()}
      <div className="ag-theme-quartz" style={{ height: "78vh", margin: 15 }}>
        {renderGrids()}
      </div>
      <ToastContainer />
    </>
  );
};

export default LeaderApproval;
