import {
  MDBBtn,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../../utils/tokenHelper";
import { useNavigate } from "react-router-dom";

function Avatar() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (isTokenExpired()) navigate(`/login`);
    var info = window.localStorage.getItem("userInfo") ?? "";
    let infoObj = JSON.parse(info);
    setUserInfo(infoObj);
  }, []);

  const handleLogOut = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("userInfo");
    navigate(`/`);
  };

  const handleUserGuide = () => {
    window.open('UserManual.docx', '_blank');
  }

  const getUserRole = () => {
    let uRole = userInfo?.role ?? "User";
    if (uRole === "Manager") return "Project Manager";
    return uRole;
  }

  return (
    <>
      <MDBDropdown light={true} style={{ backgroundColor: '#e3f2fd' }}>
        <MDBDropdownToggle split className="nav-link" role="button" >
          <MDBBtn title={userInfo?.displayName} style={{ height: 24, display: "inline-flex", alignItems: "center" }}>
            <MDBIcon fas icon="user-circle" style={{ marginRight: 15 }} /> Hi,{" "}
            {userInfo?.displayName}
          </MDBBtn>
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          <MDBDropdownItem
            onClick={() => navigate(`/users/${userInfo?.id}`)}
            className="d-flex p-2"
          >
            Welcome, <div className=" truncate max-w-[450px]" style={{ marginLeft: 10 }}> {getUserRole()}</div>
          </MDBDropdownItem>

          <MDBDropdownItem divider />
          <MDBDropdownItem
            onClick={() => navigate(`/users/settings`)}
            className="d-flex p-2"
          >
            <MDBIcon fas icon="cogs" style={{ marginRight: 15 }} />
            Settings
          </MDBDropdownItem>

          <MDBDropdownItem
            onClick={() => navigate(`/users/notifications`)}
            className="d-flex p-2"
          >
            <MDBIcon fas icon="bell" style={{ marginRight: 15 }} />
            Notifications
          </MDBDropdownItem>
          <MDBDropdownItem divider />
          <MDBDropdownItem className="d-flex p-2">
            <MDBIcon fas icon="info-circle" style={{ marginRight: 15 }} />
            Version 1.0.0
          </MDBDropdownItem>
          <MDBDropdownItem className="d-flex p-2" onClick={() => handleUserGuide()}>
            <MDBIcon fas icon="question" style={{ marginRight: 15 }} />
            User Guide
          </MDBDropdownItem>
          <MDBDropdownItem divider />
          <MDBDropdownItem
            onClick={() => handleLogOut()}
            className="d-flex p-2"
          >
            Log Out
            <MDBIcon fas icon="sign-out-alt" style={{ marginLeft: 15 }} />
          </MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>
    </>
  );
}

export default Avatar;
